import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
// @mui
import { Box, Stack, Drawer } from '@mui/material';
// hooks
import useResponsive from '../../../hooks/useResponsive';
// config
import { NAV } from '../../../config-global';
import { useAuthContext } from '../../../auth/useAuthContext';
// components
import Logo from '../../../components/logo';
import Scrollbar from '../../../components/scrollbar';
import { NavSectionVertical } from '../../../components/nav-section';
//
import navConfig from './config-navigation';
import NavDocs from './NavDocs';
import NavAccount from './NavAccount';
import NavToggleButton from './NavToggleButton';

// ----------------------------------------------------------------------

NavVertical.propTypes = {
  openNav: PropTypes.bool,
  onCloseNav: PropTypes.func,
};

export default function NavVertical({ openNav, onCloseNav }) {
  const { pathname } = useLocation();
  const { user } = useAuthContext();

  const isDesktop = useResponsive('up', 'lg');

  useEffect(() => {
    if (openNav) {
      onCloseNav();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  let pageConfig;

  function removeMyEvents(navConfig) {
    return navConfig.map((section) => {
      return {
        ...section,
        items: section.items.filter((item) => item.title !== 'my events'),
      };
    });
  }

  function removeAdminSections(navConfig) {
    return navConfig.map((section) => {
      return {
        ...section,
        items: section.items.filter((item) => item.title !== 'add prize'),
      };
    });
  }

  function isolateAdminSections(data) {
    const updatedData = data.map((item) => {
      if (item.items && Array.isArray(item.items)) {
        const updatedItems = item.items.map((subItem) => {
          if (
            subItem.children &&
            Array.isArray(subItem.children) &&
            subItem.children.length > 0
          ) {
            subItem.children = subItem.children.filter(
              (child) =>
                child.title !== 'Add/Edit Provider'
            );
          }
          return subItem;
        });
        item.items = updatedItems;
      }
      return item;
    });

    return updatedData;
  }

  if (user && user['cognito:groups']?.includes('admin')) {
    const username = user?.firstName
    console.log(user)
    if (username === 'austinjwoodard' || username === 'davis') {
      pageConfig = navConfig
    }
    else {
      pageConfig = isolateAdminSections(removeAdminSections(navConfig));
      console.log(pageConfig)
    }
  } else {
    if (!user) {
      pageConfig = removeMyEvents(navConfig).filter(item => item.subheader !== 'management').filter(item => item.subheader !== 'Provider Tools');

      console.log(pageConfig)
    } else {
      if (user && user['cognito:groups']?.includes('provider')) {
        pageConfig = navConfig.filter(item => item.subheader !== 'management');
        console.log(pageConfig)
      } else {
        pageConfig = navConfig.filter(item => item.subheader !== 'management').filter(item => item.subheader !== 'Provider Tools');
        console.log(pageConfig)
      }
    }
  }

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': {
          height: 1,
          display: 'flex',
          flexDirection: 'column',
        },
      }}
    >
      <Stack
        spacing={3}
        sx={{
          pt: 3,
          pb: 2,
          px: 2.5,
          flexShrink: 0,
        }}
      >
        <Logo />

        <NavAccount />
      </Stack>

      <NavSectionVertical data={pageConfig} />

      <Box sx={{ flexGrow: 1 }} />

      {/* <NavDocs /> */}
    </Scrollbar>
  );

  return (
    <Box
      component="nav"
      sx={{
        flexShrink: { lg: 0 },
        width: { lg: NAV.W_DASHBOARD },
      }}
    >
      <NavToggleButton />

      {isDesktop ? (
        <Drawer
          open
          variant="permanent"
          PaperProps={{
            sx: {
              zIndex: 0,
              width: NAV.W_DASHBOARD,
              bgcolor: 'transparent',
              borderRightStyle: 'dashed',
            },
          }}
        >
          {renderContent}
        </Drawer>
      ) : (
        <Drawer
          open={openNav}
          onClose={onCloseNav}
          ModalProps={{
            keepMounted: true,
          }}
          PaperProps={{
            sx: {
              width: NAV.W_DASHBOARD,
            },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </Box>
  );
}
