import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { forwardRef, useEffect, useState } from 'react';
import { createTheme, ThemeProvider } from '@mui/material';

const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const theme = createTheme();

export default function SnackbarAlert({ type = 'success', message, show, setShow}) {
  const [open, setOpen] = useState(show.status);

  useEffect(() => {
    setOpen(false);
    setTimeout(() => {
        setOpen(show.status);
    }, 200)
    
  }, [show]);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
    setShow({...show, status: false});
  };

  return (
    <ThemeProvider theme={theme}>
      <Snackbar open={open} autoHideDuration={4000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={type} sx={{ width: '100%' }}>
          {message}
        </Alert>
      </Snackbar>
    </ThemeProvider>
  );
}