import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
// @mui
import { Link, Stack, IconButton, InputAdornment, TextField, Checkbox, Typography, CircularProgress, Backdrop, Divider, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { API, Auth } from 'aws-amplify';
import { GRAPHQL_AUTH_MODE } from '@aws-amplify/api';
import * as queries from '../../../graphql/queries';
import * as customqueries from '../../../graphql/customqueries';
// components
import SnackbarAlert from '../../../components/snackbar-alert/SnackbarAlert';
import Iconify from '../../../components/iconify';
import { useAuthContext } from 'src/auth/useAuthContext';
import { useSnackbar } from 'notistack';

// ----------------------------------------------------------------------

export default function LoginForm() {
  const { login, user, isAuthenticated } = useAuthContext();
  const navigate = useNavigate();
  const [queryParams, setQueryStringParams] = useSearchParams();
  const [showPassword, setShowPassword] = useState(false);
  const [username, setUsername] = useState((queryParams.has('username') ? queryParams.get('username') : ''));
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [verifyingUser, setVerifyingUser] = useState(false);
  const [userExists, setUserExists] = useState(true);
  const [formErrors, setFormErrors] = useState({
    username: false,
    password: false
  });
  const [providers, setProviders] = useState();
  const [loadingProviders, setLoadingProviders] = useState(true);
  const [showSnackbar, setShowSnackbar] = useState({ status: false, type: 'success', message: '' });
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const auth = async () => {
    try {
      await login(username, password);
      setLoading(false);
      navigate(queryParams.get('returnTo') ? decodeURIComponent(queryParams.get('returnTo')) : '/dashboard')
    } catch (error) {
      console.log(error)
    }
  };

  const handleLogin = () => {
    if (username !== '' && password !== '') {
      handleAuth();
    } else {
      setFormErrors({
        username: (username === ''),
        password: (password === '')
      })
    }
  };

  const handleVerificationLink = () => {
    setVerifyingUser(true)
    const verificationData = JSON.parse(atob(queryParams.get('data')));
    setUsername(verificationData.userName);
    Auth.confirmSignUp(verificationData.userName, queryParams.get('code'))
      .then((response) => {
        setVerifyingUser(false)
        setShowSnackbar({
          message: 'Verification Successful! Please log in.',
          type: 'success',
          status: true
        });
      }).catch((err) => {
        console.log(err.name)
        setVerifyingUser(false)
        if (err.message === 'User cannot be confirmed. Current status is CONFIRMED') {
          setShowSnackbar({
            message: 'User already verified! Please log in.',
            type: 'success',
            status: true
          });
        } else {
          setShowSnackbar({
            message: err.message,
            type: 'error',
            status: true
          });
        }
      })
  }

  useEffect(() => {
    if (queryParams.has('data') && queryParams.has('code') && queryParams.has('verifyaccount') && queryParams.get('verifyaccount') === 'true') {
      handleVerificationLink();
    }
  }, []);

  const handleAuth = () => {
    setLoading(true);
    Auth.signIn(username, password).then((response) => {
      if (response.challengeName && response.challengeName === 'NEW_PASSWORD_REQUIRED') {
        navigate('/resetpassword');
      } else {
        API.graphql({
          query: queries.getUserProfile,
          variables: { id: response.attributes.sub },
          authMode: GRAPHQL_AUTH_MODE.API_KEY,
        }).then((response) => {
          if (response.data.getUserProfile === null) {
            auth().then(() => {
              window.location = `/register?page=profileInfo`;
            }).catch(err => console.log(err))
          } else {
            Auth.currentAuthenticatedUser().then((user) => {
              auth();
            })
          }
        }).catch(err => console.log(err))
        // navigate(`/register?page=complete`);
      }
    }).catch((err) => {
      console.log(err)
      if (err.name === 'UserNotConfirmedException') {
        setShowSnackbar({
          message: 'Login Successful!',
          type: 'Success',
          status: true
        })
        setLoading(false);
        window.location = `/register?page=verify&username=${username}`;
      }
      if (err.name === 'UserNotFoundException') {
        setLoading(false);
        setUserExists(false);
        setFormErrors({ ...formErrors, username: true });
        setShowSnackbar({
          message: 'This account does not exist',
          type: 'error',
          status: true
        })
      }
      if (err.name === 'NotAuthorizedException') {
        setLoading(false);
        setFormErrors({ username: true, password: true });
        setShowSnackbar({
          message: err.message,
          type: 'error',
          status: true
        })
        console.log(showSnackbar)
      }
    });
  }

  const listProviders = () => {
    setLoadingProviders(true)
    async function fetchAllProviders() {
      let providers = [];
      let nextToken = null;
      let hasNextPage = true;

      while (hasNextPage) {
        const response = await API.graphql({
          query: customqueries.listProviderProfiles,
          authMode: GRAPHQL_AUTH_MODE.API_KEY,
          variables: { nextToken, filter: { externalAuth: { attributeExists: true }, status: { eq: 'active' } } },

        });

        const { items, nextToken: newNextToken } = response.data.listProviderProfiles;

        providers = [...providers, ...items];
        nextToken = newNextToken;
        hasNextPage = Boolean(nextToken);
      }

      return providers;
    }

    const getProviders = async () => {
      try {
        const allProviders = await fetchAllProviders();

        setProviders(allProviders);
        setLoadingProviders(false);

      } catch (err) {
        console.log(err);
      }
    };

    getProviders();

  }

  useEffect(() => {
    listProviders();
    if (window.localStorage.getItem('glds_auth_approved')) {
      enqueueSnackbar('Not Authorized. Please contact your ISP.', { variant: 'error', anchorOrigin: { vertical: 'bottom', horizontal: 'left' } })
      window.localStorage.removeItem('glds_auth_approved')
    }
  }, [])

  return (
    <>
      <form>
        <Stack spacing={5}>
          <TextField
            name="username"
            label="Username"
            value={username}
            error={formErrors.username}
            autoComplete='username'
            autoCapitalize='off'
            onChange={(event) => {
              setFormErrors({ ...formErrors, username: false });
              setUsername(event.target.value)
            }}
            onKeyDown={(event) => {
              if (event.key === 'Enter') {
                handleLogin();
              }
            }}
          />

          <TextField
            name="password"
            label="Password"
            type={showPassword ? 'text' : 'password'}
            value={password}
            error={formErrors.password}
            autoComplete='password'
            onChange={(event) => {
              setFormErrors({ ...formErrors, password: false });
              setPassword(event.target.value)
            }}
            onKeyDown={(event) => {
              if (event.key === 'Enter') {
                handleLogin();
              }
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                    <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Stack>
      </form>
      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2.5 }}>
        <Stack direction='row' alignContent='center'>
          <Checkbox name="remember" label="Remember me" disabled checked sx={{ paddingY: 0 }} />
          <Typography component='p' variant='body1' paddingTop={0.3}>
            Stay logged in?
          </Typography>
        </Stack>
        <Link variant="subtitle2" onClick={() => navigate(`/forgotpassword${(username !== '') ? '?username=' + username : ''}`)} sx={{ cursor: 'pointer' }} underline="hover">
          Forgot password?
        </Link>
      </Stack>

      <LoadingButton sx={{ paddingY: 1.5, fontSize: 17 }} loading={loading} fullWidth size="large" type="submit" variant="contained" onClick={handleLogin}>
        Login
      </LoadingButton>

      {!loadingProviders ?
        <>

          {(providers?.length > 0) &&
            <>
              <Divider sx={{ my: 3 }}>
                OR
              </Divider>
              <FormControl
                fullWidth
                disabled={loadingProviders}
              >
                <InputLabel id="demo-simple-select-label">{loadingProviders ? 'Loading Internet Providers...' : 'Sign in through ISP'}</InputLabel>
                <Select
                  fullWidth
                  label={loadingProviders ? 'Loading Internet Providers...' : 'Sign in through ISP'}
                  variant='outlined'
                  // value={providerSelection}
                  // error={formErrors.provider}
                  onChange={(event) => {
                    console.log(event.target.value)
                    Auth.federatedSignIn({ customProvider: event.target.value }).then(() => {
                      // Login was successful
                    })
                      .catch(err => {
                        // An error occurred
                        console.error(err);
                        console.log(err);
                        console.log(err.response);
                        // Here, err should have information about the error that occurred.
                        // Depending on the error, err could be a string error message or an object with more details.
                      });
                  }}
                >
                  {(providers?.length > 0) &&
                    providers.map((provider, index) =>
                      <MenuItem key={index} value={provider.externalAuth}>
                        {provider.name}
                      </MenuItem>
                    )
                  }
                </Select>
              </FormControl>
            </>
          }
        </>
        :
        <Stack width='100%' justifyItems='center' my={2}>
          <center>
            <CircularProgress />
          </center>
        </Stack>
      }
      {!userExists && <Typography variant='body1' textAlign='center' marginTop={5}>
        <Link variant="subtitle2" underline='hover' sx={{ cursor: 'pointer' }} onClick={() => {
          navigate(`/register${(queryParams.has('event') && queryParams.get('event') === 'fgnpreview') ? `?event=${queryParams.get('event')}` : ''}`)
        }}>
          This account doesn't exist. Click here to create a new account.
        </Link>
      </Typography>}
      {userExists && <Typography variant='body1' textAlign='center' marginTop={5}>
        {console.log(`/register${(queryParams.has('event') && queryParams.get('event') === 'fgnpreview') ? `?event=${queryParams.get('event')}` : ''}`)}
        <Link variant="subtitle2" underline='hover' sx={{ cursor: 'pointer' }} onClick={() => {
          navigate(`/register${(queryParams.has('event') && queryParams.get('event') === 'fgnpreview') ? `?event=${queryParams.get('event')}` : ''}`)
        }}>
          {(providers?.length > 0) ? 'ISP not listed? Click here for other options.' : 'Need an account? Click here to register.'}
        </Link>
      </Typography>}
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={verifyingUser}
      >
        <Stack spacing={2} justifyContent='center'>
          <CircularProgress color="inherit" />
          <Typography variant='body1' textAlign='center'>
            Verifying account...
          </Typography>
        </Stack>
      </Backdrop>
      {showSnackbar.status ? <SnackbarAlert show={showSnackbar} setShow={setShowSnackbar} message={showSnackbar.message} type={showSnackbar.type} /> : null}
    </>
  );
}
