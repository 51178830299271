// @mui
import { Stack, Box } from '@mui/material';
// config
import { NAV } from '../../../config-global';
import { useAuthContext } from '../../../auth/useAuthContext';
// utils
import { hideScrollbarX } from '../../../utils/cssStyles';
// components
import Logo from '../../../components/logo';
import { NavSectionMini } from '../../../components/nav-section';
//
import navConfig from './config-navigation';
import NavToggleButton from './NavToggleButton';

// ----------------------------------------------------------------------

export default function NavMini() {
  const { user } = useAuthContext();
  let pageConfig;

  function removeMyEvents(navConfig) {
    return navConfig.map((section) => {
      return {
        ...section,
        items: section.items.filter((item) => item.title !== 'my events'),
      };
    });
  }

  function removeAdminSections(navConfig) {
    return navConfig.map((section) => {
      return {
        ...section,
        items: section.items.filter((item) => item.title !== 'Providers').filter((item) => item.title !== 'add prize'),
      };
    });
  }
  function isolateAdminSections(data) {
    const updatedData = data.map((item) => {
      if (item.items && Array.isArray(item.items)) {
        const updatedItems = item.items.map((subItem) => {
          if (
            subItem.children &&
            Array.isArray(subItem.children) &&
            subItem.children.length > 0
          ) {
            subItem.children = subItem.children.filter(
              (child) =>
                child.title !== 'Add/Edit Provider'
            );
          }
          return subItem;
        });
        item.items = updatedItems;
      }
      return item;
    });
  
    return updatedData;
  }

  if (user && user['cognito:groups']?.includes('admin')) {
    const username = user.username
    console.log(user)
    if (username === 'austinjwoodard' || username === 'davis') {
      pageConfig = navConfig
    }
    else {
      pageConfig = isolateAdminSections(removeAdminSections(navConfig));
      console.log(pageConfig)
    }
  } else {
    if (!user) {
      pageConfig = removeMyEvents(navConfig).filter(item => item.subheader !== 'management').filter(item => item.subheader !== 'Provider Tools');

      console.log(pageConfig)
    } else {
      if (user && user['cognito:groups']?.includes('provider')) {
        pageConfig = navConfig.filter(item => item.subheader !== 'management');
        console.log(pageConfig)
      } else {
        pageConfig = navConfig.filter(item => item.subheader !== 'management').filter(item => item.subheader !== 'Provider Tools');
        console.log(pageConfig)
      }
    }
  }

  return (
    <Box
      component="nav"
      sx={{
        flexShrink: { lg: 0 },
        width: { lg: NAV.W_DASHBOARD_MINI },
      }}
    >
      <NavToggleButton
        sx={{
          top: 22,
          left: NAV.W_DASHBOARD_MINI - 12,
        }}
      />

      <Stack
        sx={{
          pb: 2,
          height: 1,
          position: 'fixed',
          width: NAV.W_DASHBOARD_MINI,
          borderRight: (theme) => `dashed 1px ${theme.palette.divider}`,
          ...hideScrollbarX,
        }}
      >
        <Logo sx={{ mx: 'auto', my: 2 }} />

        <NavSectionMini data={pageConfig} />
      </Stack>
    </Box>
  );
}
