import { Button, Card, Container, CssBaseline, Divider, FormControl, Grid, InputLabel, LinearProgress, Link, List, ListItem, ListItemIcon, ListItemText, MenuItem, Select, Skeleton, ThemeProvider, Typography, createTheme, styled } from '@mui/material'
import { Helmet } from 'react-helmet-async';
import { ArrowBack, SportsEsports } from '@mui/icons-material';
import * as customqueries from '../graphql/customqueries';
import useResponsive from '../hooks/useResponsive';
import { useEffect, useRef, useState } from 'react';
import { API, Auth, Storage } from 'aws-amplify';
import { GRAPHQL_AUTH_MODE } from '@aws-amplify/auth';
import Marquee from 'react-fast-marquee';



const theme = createTheme({
    palette: {
        mode: 'dark'
    },
    typography: {
        "fontFamily": `"Metropolis"`,
        "fontWeightLight": 300,
        "fontWeightRegular": 500,
        "fontWeightMedium": 600,
        "fontWeightBold": 800
    }
});

const StyledImage = styled('img')({
});

const GameLogo = styled('img')`
  width: 200px !important;
  height: 150px !important;
  max-width: unset;
  margin-top: 60px;
  object-fit: contain !important;
  object-position: center !important;
`;



export default function IowaClashLanding() {
    const mdUp = useResponsive('up', 'md');
    const [providerSelection, setProviderSelection] = useState();
    const [loadingProviders, setLoadingProviders] = useState(false);
    const [providers, setProviders] = useState('');
    const [provider, setProvider] = useState('');
    const [providerLogos, setProviderLogos] = useState();

    const listProviders = () => {
        setLoadingProviders(true)
        async function fetchAllProviders() {
            let providers = [];
            let nextToken = null;
            let hasNextPage = true;

            while (hasNextPage) {
                const response = await API.graphql({
                    query: customqueries.listProviderProfiles,
                    authMode: GRAPHQL_AUTH_MODE.API_KEY,
                    variables: { nextToken },
                });

                const { items, nextToken: newNextToken } = response.data.listProviderProfiles;

                providers = [...providers, ...items];
                nextToken = newNextToken;
                hasNextPage = Boolean(nextToken);
            }

            return providers;
        }

        const getProviders = async () => {
            try {
                const allProviders = await fetchAllProviders();
                console.log(allProviders);
                setProviders(allProviders.filter(obj => obj.status === "active" && obj.clash === true).sort((a, b) => a.name.localeCompare(b.name)));
                setLoadingProviders(false);

            } catch (err) {
                console.log(err);
            }
        };

        getProviders();

    }
    const handleProviderSelection = (selection) => {
        setProviderSelection(selection);
        console.log(selection)
        // window.location = `/register?provider=${selection}`

        if (selection === 'noProvider') {
            handleSelectProvider(selection)
            window.location = `/register`
        } else {
            if (providers[selection].externalAuth) {
                Auth.federatedSignIn({ customProvider: providers[selection].externalAuth }).then(() => {
                    // Login was successful
                }).catch(err => {
                    // An error occurred
                    console.error(err);
                    console.log(err);
                    console.log(err.response);
                    // Here, err should have information about the error that occurred.
                    // Depending on the error, err could be a string error message or an object with more details.
                });
            } else {
                window.location = `/register?provider=${providers[selection].slug}`
                // console.log(providers[selection].slug);
            }
        }
    }

    const handleSelectProvider = (index) => {
        if (index !== 'noProvider') {
            setProvider(providers[index].id);
        } else {
            setProvider(index);
        }
    }

    useEffect(() => {
        listProviders();
    }, []);

    useEffect(() => {
        // Function to fetch all URLs
        if (providers && providers.length !== 0) {
            const fetchUrls = async () => {
                try {
                    const urls = await Promise.all(
                        providers.map(obj =>
                            // Call getUrl and then extract the url.href from the response
                            Storage.get(obj.images)
                                .then(response => response)
                                .catch(error => {
                                    console.error('Error fetching URL for key', obj, error);
                                    return null; // Return null or a default value in case of error
                                })
                        )
                    );

                    // Filter out any null values in case of errors
                    setProviderLogos(urls.filter(url => url !== null));
                } catch (error) {
                    console.error('An error occurred while fetching image URLs', error);
                }
            };

            fetchUrls();
        }
    }, [providers]);

    return (
        <>
            <Helmet bodyAttributes={{
                style: 'background: linear-gradient(145deg, #b64499, #264162 30%, #16c0dc 100%); min-height: 100vh; padding: 0; margin: 0;'
            }}>


                <title>Iowa Clash | Fiber Gaming Network</title>
            </Helmet>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <Container maxWidth='true'>
                    <Button href='/dashboard' variant='text' startIcon={<ArrowBack />} sx={{ mt: 2, color: 'white' }}>
                        Go To Home Page
                    </Button>
                </Container>
                <Container maxWidth='md'>

                    {/* ----------------------------------- Desktop ----------------------------------- */}
                    {/* Section One */}
                    <Grid container alignItems='center' justifyContent='space-around' sx={{ minHeight: { xs: '70vh', md: '50vh' } }} pb={5}>
                        <Grid item xs={12} alignSelf='baseline'>
                            <center>
                                <StyledImage src='/clash/iowa_clash_logo.svg' sx={{ maxHeight: { xs: 175, md: 200 }, mt: 10 }} />
                            </center>
                        </Grid>
                        <Grid item xs={12}>
                            {providerLogos ?
                                <Marquee gradientColor={[17, 98, 174]} speed={60}>
                                    <div style={{ marginLeft: '30px', marginRight: '30px' }}>
                                        <GameLogo src="/clash/ioca_logo.png" alt="IOCA Logo" sx={{ backgroundColor: 'white', borderRadius: 4 }} />
                                    </div>
                                    {/* <div style={{ marginLeft: '30px', marginRight: '30px' }}>
                                        <GameLogo src="/clash/IAHSEA.jpeg" alt="IAHSEA Logo" sx={{ backgroundColor: 'white', borderRadius: 4 }} />
                                    </div> */}
                                    {providerLogos.map((logo, index) =>
                                        <div key={index} style={{ marginLeft: '30px', marginRight: '30px' }}>
                                            <GameLogo src={logo} alt="provider logo" />
                                        </div>
                                    )}
                                </Marquee>
                                :
                                <LinearProgress sx={{ my: 13 }} />
                            }
                        </Grid>
                        <Grid item xs={12} mt={5}>
                            <Typography variant={mdUp ? 'h2' : 'h3'} fontWeight={700} textAlign='center'>
                                Iowa, Start Your Engines
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sx={{ mt: 15 }}>
                            <Typography fontSize={30} fontWeight={700} mt={0} textAlign="left">
                                Event Details
                            </Typography>
                            <Typography fontSize={20} fontWeight={500} mt={0} textAlign="left">
                                <b>Dates:</b> April 26-28
                                <br />
                                <b>Game:</b> Rocket League
                                <br />
                                <b>Location:</b> Virtual; play on your own device
                                <br />
                                <b>Tournament Structure:</b> 3 vs. 3, Single Elimination
                                <br />
                                <b>Registration Deadline:</b> April 24, 2024
                            </Typography>
                            <Typography fontSize={30} fontWeight={700} mt={10} textAlign="left">
                                Prizing
                            </Typography>
                            <Typography fontSize={23} fontWeight={500} mt={0} textAlign="left">
                                This tournament features a total prize purse of $7,500:
                            </Typography>
                            <List dense sx={{ mt: 0 }}>
                                <ListItem disablePadding sx={{ marginBottom: '10px' }}>
                                    <ListItemIcon color="#FFFFFF">
                                        <SportsEsports fontSize="large" sx={{ color: '#65c85f' }} />
                                    </ListItemIcon>
                                    <ListItemText color="#FFFFFF">
                                        <Typography fontWeight={300} fontSize={20}>
                                            <b>First Place Team</b> = $4,200
                                        </Typography>
                                    </ListItemText>
                                </ListItem>
                                <ListItem disablePadding sx={{ marginBottom: '10px' }}>
                                    <ListItemIcon color="#FFFFFF">
                                        <SportsEsports fontSize="large" sx={{ color: '#65c85f' }} />
                                    </ListItemIcon>
                                    <ListItemText color="#FFFFFF">
                                        <Typography fontWeight={300} fontSize={20}>
                                            <b>Second Place Team</b> = $2,100
                                        </Typography>
                                    </ListItemText>
                                </ListItem>
                                <ListItem disablePadding sx={{ marginBottom: '10px' }}>
                                    <ListItemIcon color="#FFFFFF">
                                        <SportsEsports fontSize="large" sx={{ color: '#65c85f' }} />
                                    </ListItemIcon>
                                    <ListItemText color="#FFFFFF">
                                        <Typography fontWeight={300} fontSize={20}>
                                            <b>Third Place Team</b> = $900
                                        </Typography>
                                    </ListItemText>
                                </ListItem>
                                <ListItem disablePadding sx={{ marginBottom: '10px' }}>
                                    <ListItemIcon color="#FFFFFF">
                                        <SportsEsports fontSize="large" sx={{ color: '#65c85f' }} />
                                    </ListItemIcon>
                                    <ListItemText color="#FFFFFF">
                                        <Typography fontWeight={300} fontSize={20}>
                                            <b>Fourth Place Team</b> = $300
                                        </Typography>
                                    </ListItemText>
                                </ListItem>
                            </List>
                            <Typography fontSize={16} fontWeight={500}>
                                Note: All winners must fill out and submit a W9 prior to receiving money. For winners younger than 18 years old, parental consent will be required before the prize is issued.
                            </Typography>
                            <Typography fontSize={30} fontWeight={700} mt={10} textAlign="left">
                                Eligibility
                            </Typography>
                            <Typography fontSize={20} fontWeight={500} mt={0} textAlign="left">
                                All teams of three must have <b>at least one player</b> that either (a) lives at an address serviceable by a Community Host or (b) attends a school sponsored by a Community Host or IAHSEA.
                                <br /><br />
                                In addition, all players must be 13 years of age or older and no current RLCS players are allowed to register or compete.
                                <br /><br />
                                For teams that place, proof of eligibility will be required for at least one team member before prizing will be issued.

                            </Typography>
                            <Typography fontSize={30} fontWeight={700} mt={10} textAlign="left">
                                How to Register
                            </Typography>
                            <Typography fontSize={20} fontWeight={500} mt={0} textAlign="left" sx={{ '& a': { color: 'white' } }}>
                                <b>Step 1:</b> Log in to your Fiber Gaming Network account <a href='/login'>here</a> or create an account for the first time below. You will be required to submit an eligible address OR a code from a sponsored high school in order to create your account.
                                <br /><br />
                                <b>Step 2:</b> Find the FGN Iowa Clash in the upcoming Events section and click to register.
                                <br /><br />
                                <b>Step 3:</b> Complete the form to register your team of three.
                            </Typography>
                            <Typography fontSize={20} fontWeight={700} textAlign='center' mt={10}>
                                No team? No problem! <Link href='/iowaclashfreeagent'>Sign up as a free agent.</Link>
                            </Typography>
                            <Card
                                sx={{
                                    backgroundColor: 'rgba(0, 0, 0, 0.2)',
                                    mt: 10,
                                    p: 4,
                                    borderRadius: 5
                                }}
                                elevation={0}
                            >
                                <Typography fontSize={40} fontWeight={700} textAlign='center'>
                                    Create Account
                                </Typography>
                                {/* <Divider sx={{ mb: 5, mt: 3 }} /> */}
                                <FormControl fullWidth size="large" sx={{ mt: 3 }}>
                                    <InputLabel id="demo-simple-select-label">{loadingProviders ? 'Loading Community Host...' : 'Choose Your Community Host'}</InputLabel>
                                    <Select
                                        fullWidth
                                        size="large"
                                        label={loadingProviders ? 'Loading Community Hosts...' : 'Choose Your Community Host'}
                                        variant='outlined'
                                        value={providerSelection}
                                        onChange={(event) => {
                                            handleProviderSelection(event.target.value);
                                        }}
                                    >
                                        {(providers?.length > 0) &&
                                            providers.map((provider, index) =>
                                                <MenuItem key={index} value={index}>
                                                    {provider.name}
                                                </MenuItem>
                                            )
                                        }
                                    </Select>
                                </FormControl>
                                <Divider sx={{ mt: 3 }}>OR</Divider>
                                <Typography variant="body1" textAlign='center'>
                                    <Button fullWidth size="large" sx={{ paddingY: 1.8, mt: 3, backgroundColor: 'rgba(144, 202, 249, 0.08)', color: 'white', '&:hover': { backgroundColor: 'rgba(144, 202, 249, 0.2)' } }} color="primary" href="/register?inviteCode=true">
                                        Sign Up With Invite Code
                                    </Button>
                                </Typography>
                            </Card>

                            <Typography fontSize={20} fontWeight={700} mt={10} textAlign="left">
                                The Fine Print
                            </Typography>
                            <Typography fontSize={14} fontWeight={500} mt={0} textAlign="left" sx={{ '& a': { color: 'white' } }}>
                                THIS TOURNAMENT IS NOT SPONSORED, ENDORSED, OR ADMINISTERED BY PSYONIX LLC. THE INFORMATION PLAYERS PROVIDE IN CONNECTION WITH THIS TOURNAMENT IS BEING PROVIDED TO TOURNAMENT ORGANIZER AND COMMUNITY HOST, NOT TO PSYONIX LLC.
                                <br /><br />
                                BY PARTICIPATING IN THIS TOURNAMENT, TO THE EXTENT PERMITTED BY APPLICABLE LAW, PLAYERS AGREE TO RELEASE AND HOLD HARMLESS PSYONIX LLC, ITS PARENT COMPANY, LICENSORS, ITS AND THEIR AFFILIATES, AND ITS AND THEIR EMPLOYEES, OFFICERS, DIRECTORS, AGENTS, CONTRACTORS, AND OTHER REPRESENTATIVES FROM ALL CLAIMS, DEMANDS, ACTIONS, LOSSES, LIABILITIES, AND EXPENSES RELATED TO THE TOURNAMENT.

                            </Typography>
                        </Grid>
                    </Grid>
                    <Container maxWidth='md' disableGutters sx={{ mt: { xs: 0, md: 7 } }}>
                        {/* <CognitoForm /> */}
                    </Container>
                    {/* Section Four */}
                    {/* <Grid container alignItems='center' justifyContent='space-around' minHeight='100vh' py={5}>
                        <Grid item xs={12}>
                            <Typography variant='h3' fontSize={40} fontWeight={200} textAlign='center'>
                                Schedule a Demo Today
                            </Typography>
                            <Typography variant='h5' fontSize={mdUp ? 30 : 20} textAlign='center' mt={2} mb={5}>
                                Learn more about <b>Fiber Gaming Network</b> by scheduling a demo below.
                            </Typography>
                        </Grid>
                    </Grid> */}

                    {/* Footer */}
                    <Grid container alignItems='center' justifyContent='space-around'>
                        <Grid item xs={12} md={10} pt={3}>
                            <StyledImage src='/assets/illustrations/partner_page/footer.svg' />
                        </Grid>
                    </Grid>
                    {/* ------------------------------------------------------------------------- */}
                </Container>
            </ThemeProvider>
        </>
    )
}

// const CognitoForm = () => {
//     const formRef = useRef(null); // Create a ref for the div

//     useEffect(() => {
//         // Ensure the ref current value is available
//         if (formRef.current) {
//             // Create script element
//             const script = document.createElement('script');
//             script.src = "https://www.cognitoforms.com/f/seamless.js";
//             script.async = true;
//             script.setAttribute('data-key', 'a1aiihkh_k24CeSMjXWbrg');
//             script.setAttribute('data-form', '342');

//             // Append script to the ref's current element (the div)
//             formRef.current.appendChild(script);

//             // Cleanup function to remove script when component unmounts
//             return () => {
//                 formRef.current.removeChild(script);
//             };
//         }
//     }, []); // Empty dependency array means this effect runs once on mount

//     return (
//         <Card sx={{ py: { xs: 2, md: 5 }, borderRadius: 5, backgroundColor: 'white', px: { xs: 2, md: 0 }, mb: 5 }}>
//             <div ref={formRef}>
//                 {/* This div is where the script and form will be injected */}
//             </div>
//         </Card>
//     );
// };