import { Accordion, AccordionDetails, AccordionSummary, Button, Card, Container, CssBaseline, Grid, Link, List, ListItem, ListItemIcon, ListItemText, Skeleton, ThemeProvider, Typography, createTheme, styled } from '@mui/material'
import { Helmet } from 'react-helmet-async';
import { ArrowBack, ExpandMore, SportsEsports } from '@mui/icons-material';
import useResponsive from '../hooks/useResponsive';
import { useEffect, useRef } from 'react';
import { useAuthContext } from 'src/auth/useAuthContext';
import { useNavigate } from 'react-router';


const theme = createTheme({
    palette: {
        mode: 'dark'
    },
    typography: {
        "fontFamily": `"Metropolis"`,
        "fontWeightLight": 300,
        "fontWeightRegular": 500,
        "fontWeightMedium": 600,
        "fontWeightBold": 800
    }
});

const StyledImage = styled('img')({
});



export default function KansasClashSignUp() {
    const mdUp = useResponsive('up', 'md');
    const { user } = useAuthContext();

    useEffect(() => {
        console.log(user)
    }, [user]);

    return (
        <>
            <Helmet bodyAttributes={{
                style: 'background: linear-gradient(145deg, #b64499, #264162 30%, #16c0dc 100%); min-height: 100vh; padding: 0; margin: 0;'
            }}>


                <title>FGN Kansas Clash | Fiber Gaming Network</title>
            </Helmet>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <Container maxWidth='true'>
                    <Button href='/dashboard' variant='text' startIcon={<ArrowBack />} sx={{ my: 2, color: 'white' }}>
                        Back to events
                    </Button>
                </Container>
                <Container maxWidth='lg'>
                    <Grid container justifyContent='center'>
                        <Grid item xs={12} md={5}>
                            <center>
                                <StyledImage src='/clash/kansas_clash_logo.svg' sx={{ maxHeight: { xs: 150, md: 200 }, pt: mdUp ? 7 : 0, mt: mdUp ? 0 : 3 }} />
                            </center>
                        </Grid>
                        {user &&
                            <Grid item xs={12} md={5}>
                                <center>
                                    {!mdUp && <><br /><br /></>}
                                    <StyledImage src={user?.provider?.images} sx={{ maxHeight: { xs: 150, md: 175 }, pt: mdUp ? 7 : 0, mt: mdUp ? 0 : 0 }} />
                                </center>
                            </Grid>
                        }
                    </Grid>
                    {/* ----------------------------------- Desktop ----------------------------------- */}
                    {/* Section One */}
                    <Grid container alignItems='center' justifyContent='space-around' sx={{ minHeight: { xs: '70vh', md: '50vh' } }} pb={5}>
                        <Grid item sx={{ mt: { xs: 15, md: 20 } }}>
                            <Typography variant='h3' fontWeight={400} textAlign={mdUp ? 'left' : 'center'}>
                                Register to participate in the FGN Kansas Clash!
                            </Typography>
                            <Typography variant='h5' fontWeight={500} mt={2} textAlign={mdUp ? 'left' : 'center'} lineHeight={2}>
                                Registration is free, but participants must meet the following eligibility criteria.<br /><b>Winners will be required to submit proof of eligibility before prizes are administered.</b>
                            </Typography>
                            <List dense sx={{ mt: 3 }}>
                                <ListItem disablePadding sx={{ marginBottom: '10px' }}>
                                    <ListItemIcon color="#FFFFFF">
                                        <SportsEsports fontSize="large" sx={{ color: '#65c85f' }} />
                                    </ListItemIcon>
                                    <ListItemText color="#FFFFFF">
                                        <Typography variant='h6' fontWeight={300} fontSize={22}>
                                            No current RLCS players
                                        </Typography>
                                    </ListItemText>
                                </ListItem>
                                <ListItem disablePadding sx={{ marginBottom: '10px' }}>
                                    <ListItemIcon color="#FFFFFF">
                                        <SportsEsports fontSize="large" sx={{ color: '#65c85f' }} />
                                    </ListItemIcon>
                                    <ListItemText color="#FFFFFF">
                                        <Typography variant='h6' fontWeight={300} fontSize={22}>
                                            At least one team member must live at an address serviceable by {user?.provider?.name || 'a Community Host'} or attend a school sponsored by {user?.provider?.name || 'a Community Host'}
                                            <br />
                                            <b>The other 2 players DO NOT have to meet this requirement.</b>
                                        </Typography>
                                    </ListItemText>
                                </ListItem>
                                <ListItem disablePadding sx={{ marginBottom: '10px' }}>
                                    <ListItemIcon color="#FFFFFF">
                                        <SportsEsports fontSize="large" sx={{ color: '#65c85f' }} />
                                    </ListItemIcon>
                                    <ListItemText color="#FFFFFF">
                                        <Typography variant='h6' fontWeight={300} fontSize={22}>
                                            Must be 13 years of age or older
                                        </Typography>
                                    </ListItemText>
                                </ListItem>
                            </List>
                        </Grid>
                        <Grid xs={12} mt={4}>
                            <Typography fontSize={30} fontWeight={700} mt={0} textAlign="left">
                                Event Details
                            </Typography>
                            <Typography fontSize={20} fontWeight={500} mt={0} textAlign="left">
                                <b>Dates:</b>
                                <br />
                                September 21: 11am- 3:30pm CST / 12pm-4:30pm~EST
                                <br />
                                September 22: 11am - 3:30pm CST / 12pm-4:30pm~ EST
                                <br />
                                <b>Check-In 30 minutes prior to each day's start time.</b>
                                <br />
                                <br />
                                <b>Game:</b> Rocket League
                                <br />
                                <b>Location:</b> Virtual; play on your own device
                                <br />
                                <b>Tournament Structure:</b> 3 vs. 3, Single Elimination
                                <br />
                                <b>Registration Deadline:</b> September 17, 2024
                            </Typography>
                        </Grid>
                        <Grid item xs={12} pt={8}>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMore />}
                                    aria-controls="panel1-content"
                                    id="panel1-header"
                                    style={{
                                        fontSize: 23
                                    }}
                                >
                                    Rules and Terms
                                </AccordionSummary>
                                <AccordionDetails sx={{ '& ul': { pl: 3 } }}>
                                    <ol>
                                        <li>Introduction and Acceptance
                                            <ol>
                                                <li>Introduction
                                                    <p>These rules govern the FGN Clash Rocket League Tournament ("Tournament"). The Tournament is provided by FGN eSports and is open to eligible participants in Kansas. These rules are designed to ensure fair play, promote competition, and maintain a positive gaming environment.</p>
                                                </li>
                                                <li>Acceptance
                                                    <p>To participate, each player must agree to follow these rules, including the Code of Conduct. Players (or their parents/legal guardians for Minors) must accept these rules during registration. Failure to comply may result in penalties, as outlined in Section 8.</p>
                                                </li>
                                                <li>Enforcement
                                                    <p>FGN eSports will enforce these rules, and penalties may be issued for violations. Amendments to these rules may occur, and participants agree to accept them by continuing participation.</p>
                                                </li>
                                            </ol>
                                        </li>
                                        <li>Eligibility
                                            <ol>
                                                <li>Residency Requirement
                                                    <p>Teams must have at least one Kansas resident with a serviceable ISP address on their roster. Alternatively, participation is allowed for teams from local schools.</p>
                                                </li>
                                            </ol>
                                        </li>
                                        <li>Game Play Rules
                                            <ol>
                                                <li>Match Settings
                                                    <ol>
                                                        <li>Game Settings
                                                            <ul>
                                                                <li>Default Arena: DFH Stadium</li>
                                                                <li>Team Size: 3v3</li>
                                                                <li>Bot Difficulty: No Bots</li>
                                                                <li>Mutators: None</li>
                                                                <li>Match Time: 5 Minutes</li>
                                                                <li>Joinable By: Name/Password</li>
                                                                <li>Platform: Epic Games Store, PlayStation, Nintendo Switch, Steam, or Xbox</li>
                                                                <li>Server: US-East (NA League)</li>
                                                            </ul>
                                                        </li>
                                                        <li>Controllers
                                                            <ul>
                                                                <li>All standard controllers, including mouse and keyboard, are legal.</li>
                                                                <li>Macro functions are not permitted.</li>
                                                                <li>Wireless controllers are not allowed at in-person events.</li>
                                                            </ul>
                                                        </li>
                                                        <li>Arenas
                                                            <ul>
                                                                <li>In the Open Qualifier, all games are played on DFH Stadium.</li>
                                                                <li>Map rotation for subsequent stages will be chosen by Tournament Administrators from eligible arenas.</li>
                                                            </ul>
                                                        </li>
                                                    </ol>
                                                </li>
                                                <li>Match Procedures
                                                    <ol>
                                                        <li>Hosting and Team Colors
                                                            <ul>
                                                                <li>Tournament Administrators will specify team colors.</li>
                                                                <li>In the Open Qualifier, teams host the match; in other stages, a Tournament Administrator hosts.</li>
                                                            </ul>
                                                        </li>
                                                        <li>Re-Hosts
                                                            <ul>
                                                                <li>Teams may request re-hosts for connection issues.</li>
                                                                <li>Re-hosts during a Main Event require mutual agreement before a goal or within fifteen seconds.</li>
                                                            </ul>
                                                        </li>
                                                        <li>Game Start
                                                            <ul>
                                                                <li>Players join designated sides as instructed.</li>
                                                            </ul>
                                                        </li>
                                                        <li>Substitutions
                                                            <ul>
                                                                <li>Substitutions allowed in between games or before a match.</li>
                                                                <li>Limit one substitution per match.</li>
                                                            </ul>
                                                        </li>
                                                        <li>Reporting Scores
                                                            <ul>
                                                                <li>Winning team submits the match result; the losing team confirms.</li>
                                                                <li>Screenshots or replays required for dispute resolution.</li>
                                                            </ul>
                                                        </li>
                                                        <li>Guest Accounts
                                                            <ul>
                                                                <li>Players must use valid Epic Games, Steam, Microsoft®, Nintendo, or PlayStationTM accounts.</li>
                                                                <li>Guest accounts are not allowed.</li>
                                                            </ul>
                                                        </li>
                                                        <li>Observers
                                                            <ul>
                                                                <li>In-game observers are not allowed except for authorized Tournament Administrators.</li>
                                                                <li>Players may stream with Psyonix approval.</li>
                                                            </ul>
                                                        </li>
                                                        <li>Bugs and Glitches
                                                            <ul>
                                                                <li>In the event of a bug or glitch, the full match should be played out. Tournament Administrators may intervene if necessary.</li>
                                                            </ul>
                                                        </li>
                                                    </ol>
                                                </li>
                                                <li>Match Obligations
                                                    <ol>
                                                        <li>Punctuality
                                                            <ul>
                                                                <li>Teams must have three players ready by the designated start time.</li>
                                                                <li>After five minutes, penalties, including forfeiture, may apply.</li>
                                                            </ul>
                                                        </li>
                                                        <li>Forfeits
                                                            <ul>
                                                                <li>Teams may not voluntarily forfeit without authorization and may face penalties even with authorization.</li>
                                                            </ul>
                                                        </li>
                                                        <li>Communications
                                                            <ul>
                                                                <li>Teams communicate in designated Discord chatrooms during online stages.</li>
                                                                <li>For live events, communication outside the match is prohibited.</li>
                                                            </ul>
                                                        </li>
                                                    </ol>
                                                </li>
                                                <li>Match Disruptions
                                                    <ol>
                                                        <li>Disconnects
                                                            <ul>
                                                                <li>Protocols for handling disconnects defined based on Open Qualifier or Main Event stage.</li>
                                                            </ul>
                                                        </li>
                                                    </ol>
                                                </li>
                                            </ol>
                                        </li>
                                        <li>Team and Player Names, Logos, Avatars, and Branding Restrictions
                                            <ol>
                                                <li>Naming Standards
                                                    <ul>
                                                        <li>Team and player names must adhere to the Code of Conduct.</li>
                                                        <li>Psyonix and Tournament Administrators may restrict or change tags for any reason.</li>
                                                    </ul>
                                                </li>
                                                <li>Naming Restrictions
                                                    <ul>
                                                        <li>Names cannot include terms like Rocket League®, Psyonix, or any trademarked material.</li>
                                                        <li>Impersonation of other entities is prohibited.</li>
                                                    </ul>
                                                </li>
                                                <li>Agreed Upon Identifier
                                                    <ul>
                                                        <li>Teams and Players must use an agreed-upon identifier determined before the Tournament.</li>
                                                    </ul>
                                                </li>
                                                <li>Logos and Branding
                                                    <ul>
                                                        <li>Teams qualifying for specific stages must provide logos in approved formats.</li>
                                                        <li>Logos may be rejected, and standard Tournament logos may replace them.</li>
                                                    </ul>
                                                </li>
                                                <li>Good Standing
                                                    <ul>
                                                        <li>Players must be in good standing with Psyonix accounts, free of undisclosed violations.</li>
                                                    </ul>
                                                </li>
                                                <li>Additional Restrictions
                                                    <ul>
                                                        <li>Tournament is open to eligible players within the Eligibility Area.</li>
                                                        <li>Void where restricted or prohibited by law.</li>
                                                    </ul>
                                                </li>
                                            </ol>
                                        </li>
                                        <li>Code of Conduct
                                            <ol>
                                                <li>Personal Conduct; No Toxic Behavior
                                                    <ul>
                                                        <li>Players must conduct themselves with integrity, respect, and good sportsmanship.</li>
                                                        <li>Harassment, disruptive behavior, or toxic conduct is strictly prohibited.</li>
                                                    </ul>
                                                </li>
                                                <li>Competitive Integrity
                                                    <ul>
                                                        <li>Players must play within the spirit of Rocket League, and unfair play is prohibited.</li>
                                                        <li>Examples of unfair play outlined, including collusion, hacking, and exploiting.</li>
                                                    </ul>
                                                </li>
                                                <li>Wagering
                                                    <ul>
                                                        <li>Betting, wagering, or gambling on the Tournament is prohibited.</li>
                                                    </ul>
                                                </li>
                                                <li>Harassment
                                                    <ul>
                                                        <li>Prohibition of harassing, abusive, or discriminatory conduct.</li>
                                                        <li>Reporting mechanism provided for victims or witnesses.</li>
                                                    </ul>
                                                </li>
                                                <li>Confidentiality
                                                    <ul>
                                                        <li>Players may not disclose confidential information provided by Tournament Administrators or Psyonix.</li>
                                                    </ul>
                                                </li>
                                                <li>Illegal Conduct
                                                    <ul>
                                                        <li>Players must comply with all applicable laws at all times.</li>
                                                    </ul>
                                                </li>
                                            </ol>
                                        </li>
                                        <li>Rules and Conduct Violations
                                            <ol>
                                                <li>Investigation and Compliance
                                                    <ul>
                                                        <li>Players must cooperate with investigations into rule violations.</li>
                                                    </ul>
                                                </li>
                                                <li>Disciplinary Action
                                                    <ul>
                                                        <li>Various disciplinary actions may be taken for rule violations.</li>
                                                        <li>Repeated breaches may result in escalating penalties, up to permanent disqualification.</li>
                                                    </ul>
                                                </li>
                                                <li>Rule Disputes
                                                    <ul>
                                                        <li>Psyonix has final authority in deciding rule disputes.</li>
                                                    </ul>
                                                </li>
                                            </ol>
                                        </li>
                                        <li>Conditions
                                            <ol>
                                                <li>Acceptance of Rules
                                                    <ul>
                                                        <li>Participants agree to be bound by these complete Rules, with decisions of Psyonix being final and binding.</li>
                                                        <li>Waiver of claim ambiguity, release of liability, and acknowledgment of tournament conditions.</li>
                                                    </ul>
                                                </li>
                                            </ol>
                                        </li>
                                        <li>Publicity
                                            <ul>
                                                <li><p>Psyonix reserves the right to use participant information for publicity purposes.</p></li>
                                            </ul>
                                        </li>
                                        <li>Team Representatives and Interviews
                                            <ul>
                                                <li><p>Teams must designate a Team Representative for scheduled interviews. Interview scheduling, attendance requirements, and use of alternate representatives outlined.</p></li>
                                            </ul>
                                        </li>
                                        <li>Waiver of Jury Trial
                                            <ul>
                                                <li><p>Participants waive the right to a trial by jury.</p></li>
                                            </ul>
                                        </li>
                                        <li>Privacy
                                            <ul>
                                                <li><p>Participants referred to Psyonix's privacy policy for information on personal data collection and usage.</p></li>
                                            </ul>
                                        </li>
                                    </ol>
                                </AccordionDetails>
                            </Accordion>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography fontSize={30} fontWeight={700} textAlign='center' my={10}>
                                <Link href='https://discord.gg/fgn' target='_BLANK'>Join our discord</Link> for all communications regarding the tournament
                            </Typography>
                            <Typography fontSize={20} fontWeight={700} textAlign='center' mt={10}>
                                No team? No problem! <Link href='/kansasclashfreeagent'>Sign up as a free agent.</Link>
                            </Typography>
                        </Grid>
                    </Grid>
                    <Container maxWidth='md' disableGutters sx={{ mt: { xs: 0, md: 7 } }}>
                        <CognitoForm />
                    </Container>
                    {/* Section Four */}
                    {/* <Grid container alignItems='center' justifyContent='space-around' minHeight='100vh' py={5}>
                        <Grid item xs={12}>
                            <Typography variant='h3' fontSize={40} fontWeight={200} textAlign='center'>
                                Schedule a Demo Today
                            </Typography>
                            <Typography variant='h5' fontSize={mdUp ? 30 : 20} textAlign='center' mt={2} mb={5}>
                                Learn more about <b>Fiber Gaming Network</b> by scheduling a demo below.
                            </Typography>
                        </Grid>
                    </Grid> */}

                    {/* Footer */}
                    <Grid container alignItems='center' justifyContent='space-around'>
                        <Grid item xs={12} md={10} pt={3}>
                            <StyledImage src='/assets/illustrations/partner_page/footer.svg' />
                        </Grid>
                    </Grid>
                    {/* ------------------------------------------------------------------------- */}
                </Container>
            </ThemeProvider>
        </>
    )
}

const convertStateCodeToFullName = (code) => {
    const stateMap = {
        "AL": "Alabama", "AK": "Alaska", "AZ": "Arizona", "AR": "Arkansas", "CA": "California",
        "CO": "Colorado", "CT": "Connecticut", "DE": "Delaware", "FL": "Florida", "GA": "Georgia",
        "HI": "Hawaii", "ID": "Idaho", "IL": "Illinois", "IN": "Indiana", "IA": "Iowa",
        "KS": "Kansas", "KY": "Kentucky", "LA": "Louisiana", "ME": "Maine", "MD": "Maryland",
        "MA": "Massachusetts", "MI": "Michigan", "MN": "Minnesota", "MS": "Mississippi", "MO": "Missouri",
        "MT": "Montana", "NE": "Nebraska", "NV": "Nevada", "NH": "New Hampshire", "NJ": "New Jersey",
        "NM": "New Mexico", "NY": "New York", "NC": "North Carolina", "ND": "North Dakota", "OH": "Ohio",
        "OK": "Oklahoma", "OR": "Oregon", "PA": "Pennsylvania", "RI": "Rhode Island", "SC": "South Carolina",
        "SD": "South Dakota", "TN": "Tennessee", "TX": "Texas", "UT": "Utah", "VT": "Vermont",
        "VA": "Virginia", "WA": "Washington", "WV": "West Virginia", "WI": "Wisconsin", "WY": "Wyoming",
    };

    return stateMap[code.toUpperCase()] || "";
};

// Function to parse the address string into an object, returns empty strings for issues
const parseAddress = (address) => {
    const parts = address.split(',').map(part => part.trim());
    const street = parts.length > 0 ? parts[0] : "";
    const city = parts.length > 1 ? parts[1] : "";
    const stateCode = parts.length > 2 ? parts[2] : "";
    const nation = parts.length > 3 ? parts[3] : "";

    return {
        street,
        city,
        state: convertStateCodeToFullName(stateCode),
        nation
    };
};

const CognitoForm = () => {
    const formRef = useRef(null); // Create a ref for the div
    const { user } = useAuthContext()
    const navigate = useNavigate();

    useEffect(() => {
        if (user && user?.provider?.kansasClash) {
            const addressParts = parseAddress(user?.address)
            // Ensure the ref current value is available
            if (formRef.current) {
                // Create script element for Cognito Forms
                const script = document.createElement('script');
                script.src = "https://www.cognitoforms.com/f/seamless.js";
                script.async = true;
                script.setAttribute('data-key', 'a1aiihkh_k24CeSMjXWbrg');
                script.setAttribute('data-form', '376');

                // Create a function to load prefill script after the main script is loaded
                const loadPrefillScript = () => {
                    // Prefill script logic
                    const prefillScript = document.createElement('script');
                    prefillScript.textContent = `
                    Cognito.prefill({
                        "TeamCaptain" : {"Name": {"First":"${user?.firstName}","Last":"${user?.lastName}"},
                        "Email": "${user?.email}",
                        "Address":{"Line1":"${addressParts?.street}","Line2":"","City":"${addressParts?.city}","State":"${addressParts?.state}","PostalCode":"${user?.zipCode}","Country":"${addressParts?.nation}"},
                        "DiscordUsername": "${user?.discordUsername || ""}"
                        },
                        "FGNUsername": "${user?.id}",
                        "FGNPartner": "${user?.provider?.name}"
                    });
                `;
                    document.body.appendChild(prefillScript);

                    // Optional: Remove the prefill script after it's executed
                    document.body.removeChild(prefillScript);
                };

                // Listen for the main script to load, then load the prefill script
                script.onload = () => {
                    loadPrefillScript();
                };

                // Append script to the ref's current element (the div)
                formRef.current.appendChild(script);

                // Cleanup function to remove script when component unmounts
                return () => {
                    if (formRef.current) {
                        formRef.current.removeChild(script);
                    }
                };
            }
        } else {
            navigate('/dashboard')
        }
    }, [user]); // Empty dependency array means this effect runs once on mount

    return (
        <Card sx={{ py: { xs: 2, md: 5 }, borderRadius: 5, backgroundColor: 'white', px: { xs: 2, md: 0 }, mb: 5 }}>
            <div ref={formRef}>
                {/* This div is where the script and form will be injected */}
            </div>
        </Card>
    );
};