import PropTypes from 'prop-types';
import { memo } from 'react';
// @mui
import { useTheme } from '@mui/material/styles';
import { AppBar, Box, Toolbar } from '@mui/material';
// config
import { HEADER } from '../../../config-global';
import { useAuthContext } from '../../../auth/useAuthContext';
// utils
import { bgBlur } from '../../../utils/cssStyles';
// components
import { NavSectionHorizontal } from '../../../components/nav-section';
//
import navConfig from './config-navigation';

// ----------------------------------------------------------------------

function NavHorizontal() {
  const theme = useTheme();
  const { user } = useAuthContext();

  let pageConfig;

  function removeObjectsByTitle(arr, title) {
    let filteredArr;
    filteredArr = arr.filter(obj =>
      obj.items.some(item => item.title === title)
    );
    console.log(filteredArr);
    return filteredArr;
  }

  function removeMyEvents(navConfig) {
    return navConfig.map((section) => {
      return {
        ...section,
        items: section.items.filter((item) => item.title !== 'my events'),
      };
    });
  }

  function removeAdminSections(navConfig) {
    return navConfig.map((section) => {
      return {
        ...section,
        items: section.items.filter((item) => item.title !== 'Providers').filter((item) => item.title !== 'add prize'),
      };
    });
  }
  function isolateAdminSections(data) {
    const updatedData = data.map((item) => {
      if (item.items && Array.isArray(item.items)) {
        const updatedItems = item.items.map((subItem) => {
          if (
            subItem.children &&
            Array.isArray(subItem.children) &&
            subItem.children.length > 0
          ) {
            subItem.children = subItem.children.filter(
              (child) =>
                child.title !== 'Add/Edit Provider'
            );
          }
          return subItem;
        });
        item.items = updatedItems;
      }
      return item;
    });
  
    return updatedData;
  }

  if (user && user['cognito:groups']?.includes('admin')) {
    const username = user?.firstName
    console.log(user)
    if (username === 'austinjwoodard' || username === 'davis') {
      pageConfig = navConfig
    }
    else {
      pageConfig = isolateAdminSections(removeAdminSections(navConfig));
      console.log(pageConfig)
    }
  } else {
    if (!user) {
      pageConfig = removeMyEvents(navConfig).filter(item => item.subheader !== 'management').filter(item => item.subheader !== 'Provider Tools');

      console.log(pageConfig)
    } else {
      if (user && user['cognito:groups']?.includes('provider')) {
        pageConfig = navConfig.filter(item => item.subheader !== 'management');
        console.log(pageConfig)
      } else {
        pageConfig = navConfig.filter(item => item.subheader !== 'management').filter(item => item.subheader !== 'Provider Tools');
        console.log(pageConfig)
      }
    }
  }

  return (
    <AppBar
      component="nav"
      color="transparent"
      sx={{
        boxShadow: 0,
        top: HEADER.H_DASHBOARD_DESKTOP_OFFSET,
      }}
    >
      <Toolbar
        sx={{
          ...bgBlur({
            color: theme.palette.background.default,
          }),
        }}
      >
        <NavSectionHorizontal data={pageConfig} />
      </Toolbar>

      <Shadow />
    </AppBar>
  );
}

export default memo(NavHorizontal);

// ----------------------------------------------------------------------

Shadow.propTypes = {
  sx: PropTypes.object,
};

function Shadow({ sx, ...other }) {
  return (
    <Box
      sx={{
        left: 0,
        right: 0,
        bottom: 0,
        height: 24,
        zIndex: -1,
        width: 1,
        m: 'auto',
        borderRadius: '50%',
        position: 'absolute',
        boxShadow: (theme) => theme.customShadows.z8,
        ...sx,
      }}
      {...other}
    />
  );
}
