import { Storage } from "aws-amplify";

export default async function keyToImageUrl(key) {
    const blob = (await Storage.get(key, { download: true })).Body;
    console.log(blob)
    return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onloadend = () => {
            resolve(reader.result);
        };

        reader.onerror = reject;

        if (blob.type.startsWith('image/svg')) {

            const textReader = new FileReader();

            textReader.onloadend = () => {
                const svgString = textReader.result;
                const svgUrl = URL.createObjectURL(new Blob([svgString], { type: 'image/svg+xml' }));
                resolve(svgUrl);
            };

            textReader.onerror = reject;
            textReader.readAsText(blob);

        } else if (blob.type.startsWith('image/')) {
            reader.readAsDataURL(blob);
        } else {
            reject(new Error('Unsupported blob type'));
        }
    });
}