import PropTypes from 'prop-types';
import { createContext, useEffect, useReducer, useCallback, useMemo } from 'react';
import * as queries from '../graphql/queries';
import * as customqueries from '../graphql/customqueries';
import { GRAPHQL_AUTH_MODE } from '@aws-amplify/api';
// utils
import axios from '../utils/axios';
import localStorageAvailable from '../utils/localStorageAvailable';
//
import { isValidToken, setSession } from './utils';
import { API, Auth } from 'aws-amplify';
import keyToImageUrl from 'src/utils/keyToImageUrl';

// ----------------------------------------------------------------------

// NOTE:
// We only build demo at basic level.
// Customer will need to do some extra handling yourself if you want to extend the logic and other features...

// ----------------------------------------------------------------------

const initialState = {
  isInitialized: false,
  isAuthenticated: false,
  isAuthorized: false,
  user: null,
};

const reducer = (state, action) => {
  if (action.type === 'INITIAL') {
    return {
      ...state,
      isInitialized: true,
      isAuthenticated: action.payload.isAuthenticated,
      user: action.payload.user,
    };
  }
  if (action.type === 'LOGIN') {
    return {
      ...state,
      isAuthenticated: true,
      user: action.payload.user,
    };
  }
  if (action.type === 'REGISTER') {
    return {
      ...state,
      isAuthenticated: true,
      user: action.payload.user,
    };
  }
  if (action.type === 'LOGOUT') {
    return {
      ...state,
      isAuthenticated: false,
      user: null,
    };
  }
  if (action.type === 'UNAUTHORIZED') {
    return {
      ...state,
      isAuthenticated: false,
      isAuthorized: true,
      user: null,
    };
  }
  if (action.type === 'UPDATE_USER') {
    return {
      ...state,
      user: action.payload.user,
    };
  }

  return state;
};

// ----------------------------------------------------------------------

export const AuthContext = createContext(null);

// ----------------------------------------------------------------------

AuthProvider.propTypes = {
  children: PropTypes.node,
};

const replaceImageWithUrl = async (obj) => {
  try {
    const images = await keyToImageUrl(obj.images);
    const darkLogo = await keyToImageUrl(obj.darkLogo);
    return {
      ...obj,
      images,
      darkLogo
    };
  } catch (error) {
    console.log(`Error loading image '${obj.images}':`, error);
    return {
      ...obj
    };
  }
};

const replaceImagesWithUrls = async (imageStorageKeys) => {
  const imageUrls = await replaceImageWithUrl(imageStorageKeys);
  return imageUrls;
};

export function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const storageAvailable = localStorageAvailable();

  const updateUser = useCallback((user) => {
    dispatch({
      type: 'UPDATE_USER',
      payload: {
        user,
      },
    });
  }, []);

  const initialize = useCallback(async () => {
    try {
      const checkAccessToken = storageAvailable ? localStorage.getItem('accessToken') : '';

      let accessToken;

      if (checkAccessToken) {
        accessToken = (await Auth.currentSession()).getAccessToken().getJwtToken()
      } else {
        accessToken = checkAccessToken;
      }

      if (accessToken && isValidToken(accessToken)) {
        setSession(accessToken);

        async function fetchProviderDetails(id) {
          try {
            const providerDetails = await API.graphql({
              query: customqueries.getProviderProfile,
              variables: { id },
              authMode: GRAPHQL_AUTH_MODE.API_KEY
            });
            if (providerDetails.data.getProviderProfile.status === 'active') {
              return providerDetails;
            } else {
              return false
            }

          } catch (error) {
            console.log('Error fetching provider details:', error);
            throw error;
          }
        }

        const response = await Auth.currentAuthenticatedUser();

        console.log(response)

        const userDetails = await API.get('fgnapi', '/userDetails/getUserDetails')

        let providerDetails;
        if (userDetails.provider && userDetails.provider !== 'noProvider') {
          providerDetails = await fetchProviderDetails(userDetails.provider);
          providerDetails = await replaceImagesWithUrls(providerDetails.data.getProviderProfile)
          console.log(providerDetails);
        } else {
          providerDetails = null
        }


        const user = { ...userDetails, providerMarketing: response.signInUserSession.idToken.payload.providerMarketingInformation || null, 'cognito:groups': response.signInUserSession.idToken.payload['cognito:groups'], provider: providerDetails, ...(response.attributes['custom:mba_code'] && { externalProvider: true }) };

        dispatch({
          type: 'INITIAL',
          payload: {
            isAuthenticated: true,
            user,
          },
        });
      } else {
        dispatch({
          type: 'INITIAL',
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    } catch (error) {
      console.error(error);
      dispatch({
        type: 'INITIAL',
        payload: {
          isAuthenticated: false,
          user: null,
        },
      });
    }
  }, [storageAvailable]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  // LOGIN
  const login = useCallback(async (username, password, oauth) => {

    async function fetchProviderDetails(id) {
      try {
        const providerDetails = await API.graphql({
          query: customqueries.getProviderProfile,
          variables: { id },
          authMode: GRAPHQL_AUTH_MODE.API_KEY
        });
        if (providerDetails.data.getProviderProfile.status === 'active') {
          return providerDetails;
        } else {
          return false
        }

      } catch (error) {
        console.log('Error fetching provider details:', error);
        throw error;
      }
    }

    if (!oauth) {
      const response = await Auth.signIn(username, password);

      console.log(response)
    }

    let response;

    try {
      response = await Auth.currentAuthenticatedUser();
      console.log(response)
    }
    catch (error) {
      console.log(error)
      if (oauth) {
        console.log(oauth)
        dispatch({
          type: 'UNAUTHORIZED',
        });
      }
    }


    try {

      const userDetails = await API.get('fgnapi', '/userDetails/getUserDetails')

      console.log(userDetails)

      let providerDetails;
      if (userDetails.provider && userDetails.provider !== 'noProvider') {
        providerDetails = await fetchProviderDetails(userDetails.provider);
        providerDetails = await replaceImagesWithUrls(providerDetails.data.getProviderProfile)
        console.log(providerDetails);
      } else {
        providerDetails = null
      }

      const accessToken = response.signInUserSession.accessToken.jwtToken;
      const user = { ...userDetails, providerMarketing: response.signInUserSession.idToken.payload.providerMarketingInformation || null, 'cognito:groups': response.signInUserSession.idToken.payload['cognito:groups'], provider: providerDetails, ...(response.attributes['custom:mba_code'] && { externalProvider: true }) };
      console.log(user);
      // const { accessToken, user } = response.data;

      setSession(accessToken);

      dispatch({
        type: 'LOGIN',
        payload: {
          user,
        },
      });
    } catch (error) {
      console.log(error)
      if (oauth) {
        console.log(oauth)
        dispatch({
          type: 'UNAUTHORIZED'
        });
      }
    }
  }, []);

  // REGISTER
  const register = useCallback(async (email, password, firstName, lastName) => {
    const response = await axios.post('/api/account/register', {
      email,
      password,
      firstName,
      lastName,
    });
    const { accessToken, user } = response.data;

    localStorage.setItem('accessToken', accessToken);

    dispatch({
      type: 'REGISTER',
      payload: {
        user,
      },
    });
  }, []);

  // LOGOUT
  const logout = useCallback(() => {
    Auth.signOut().then(response => {
      setSession(null);
      dispatch({
        type: 'LOGOUT',
      });
    })
  }, []);

  const memoizedValue = useMemo(
    () => {
      console.log(state)
      return ({
      isInitialized: state.isInitialized,
      isAuthenticated: state.isAuthenticated,
      isAuthorized: state.isAuthorized,
      user: state.user,
      method: 'jwt',
      login,
      register,
      logout,
      updateUser,
    })},
    [state.isAuthenticated, state.isAuthorized, state.isInitialized, state.user, login, logout, register, updateUser]
  );

  return <AuthContext.Provider value={memoizedValue}>{children}</AuthContext.Provider>;
}
