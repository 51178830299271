import { Card, Container, CssBaseline, Grid, List, ListItem, ListItemIcon, ListItemText, Skeleton, ThemeProvider, Typography, createTheme, styled } from '@mui/material'
import { Helmet } from 'react-helmet-async';
import { SportsEsports } from '@mui/icons-material';
import useResponsive from '../hooks/useResponsive';
import { useEffect, useRef } from 'react';


const theme = createTheme({
    palette: {
        mode: 'dark'
    },
    typography: {
        "fontFamily": `"Metropolis"`,
        "fontWeightLight": 300,
        "fontWeightRegular": 500,
        "fontWeightMedium": 600,
        "fontWeightBold": 800
    }
});

const StyledImage = styled('img')({
});



export default function ClashLanding() {
    const mdUp = useResponsive('up', 'md');

    return (
        <>
            <Helmet bodyAttributes={{
                style: 'background: linear-gradient(145deg, #b64499, #264162 30%, #16c0dc 100%); min-height: 100vh; padding: 0; margin: 0;'
            }}>


                <title>Clash | Fiber Gaming Network</title>
            </Helmet>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <Container maxWidth='lg'>

                    {/* ----------------------------------- Desktop ----------------------------------- */}
                    {/* Section One */}
                    <Grid container alignItems='center' justifyContent='space-around' sx={{ minHeight: { xs: '70vh', md: '50vh' } }} pb={5}>
                        <Grid item xs={12} alignSelf='baseline' maxHeight={100}>
                            <center>
                                <StyledImage src='/clash/clash_logo_partial_reverse.svg' sx={{ maxHeight: { xs: 100, md: 175 }, mt: 7 }} />
                            </center>
                        </Grid>
                        <Grid item sx={{ mt: { xs: 20, md: 35 } }}>
                            <Typography variant='h3' fontWeight={400} textAlign={mdUp ? 'left' : 'center'}>
                                Bring the Excitement of<br />Esports to Your Community!
                            </Typography>
                            <Typography variant='h5' fontWeight={500} mt={2} textAlign={mdUp ? 'left' : 'center'}>
                                Fill out the form below to learn more about becoming a Community Host and:
                            </Typography>
                            <List dense sx={{ mt: 3 }}>
                                <ListItem disablePadding sx={{ marginBottom: '10px' }}>
                                    <ListItemIcon color="#FFFFFF">
                                        <SportsEsports fontSize="large" sx={{ color: '#65c85f' }} />
                                    </ListItemIcon>
                                    <ListItemText color="#FFFFFF">
                                        <Typography variant='h6' fontWeight={300} fontSize={22}>
                                            Provide exclusive tournament access to your community and schools
                                        </Typography>
                                    </ListItemText>
                                </ListItem>
                                <ListItem disablePadding sx={{ marginBottom: '10px' }}>
                                    <ListItemIcon color="#FFFFFF">
                                        <SportsEsports fontSize="large" sx={{ color: '#65c85f' }} />
                                    </ListItemIcon>
                                    <ListItemText color="#FFFFFF">
                                        <Typography variant='h6' fontWeight={300} fontSize={22}>
                                            Be featured in all local marketing materials and on the live stream
                                        </Typography>
                                    </ListItemText>
                                </ListItem>
                                <ListItem disablePadding sx={{ marginBottom: '10px' }}>
                                    <ListItemIcon color="#FFFFFF">
                                        <SportsEsports fontSize="large" sx={{ color: '#65c85f' }} />
                                    </ListItemIcon>
                                    <ListItemText color="#FFFFFF">
                                        <Typography variant='h6' fontWeight={300} fontSize={22}>
                                            Get access to local registration data for follow-up engagement
                                        </Typography>
                                    </ListItemText>
                                </ListItem>
                            </List>
                        </Grid>
                    </Grid>
                    <Container maxWidth='md' disableGutters sx={{ mt: { xs: 0, md: 7 } }}>
                        <CognitoForm />
                    </Container>
                    {/* Section Four */}
                    {/* <Grid container alignItems='center' justifyContent='space-around' minHeight='100vh' py={5}>
                        <Grid item xs={12}>
                            <Typography variant='h3' fontSize={40} fontWeight={200} textAlign='center'>
                                Schedule a Demo Today
                            </Typography>
                            <Typography variant='h5' fontSize={mdUp ? 30 : 20} textAlign='center' mt={2} mb={5}>
                                Learn more about <b>Fiber Gaming Network</b> by scheduling a demo below.
                            </Typography>
                        </Grid>
                    </Grid> */}

                    {/* Footer */}
                    <Grid container alignItems='center' justifyContent='space-around'>
                        <Grid item xs={12} md={10} pt={3}>
                            <StyledImage src='/assets/illustrations/partner_page/footer.svg' />
                        </Grid>
                    </Grid>
                    {/* ------------------------------------------------------------------------- */}
                </Container>
            </ThemeProvider>
        </>
    )
}

const CognitoForm = () => {
    const formRef = useRef(null); // Create a ref for the div

    useEffect(() => {
        // Ensure the ref current value is available
        if (formRef.current) {
            // Create script element
            const script = document.createElement('script');
            script.src = "https://www.cognitoforms.com/f/seamless.js";
            script.async = true;
            script.setAttribute('data-key', 'a1aiihkh_k24CeSMjXWbrg');
            script.setAttribute('data-form', '342');

            // Append script to the ref's current element (the div)
            formRef.current.appendChild(script);

            // Cleanup function to remove script when component unmounts
            return () => {
                formRef.current.removeChild(script);
            };
        }
    }, []); // Empty dependency array means this effect runs once on mount

    return (
        <Card sx={{ py: { xs: 2, md: 5 }, borderRadius: 5, backgroundColor: 'white', px: { xs: 2, md: 0 }, mb: 5 }}>
            <div ref={formRef}>
                {/* This div is where the script and form will be injected */}
            </div>
        </Card>
    );
};