import { memo, useEffect, useMemo, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
// @mui
import { Link, Stack, IconButton, InputAdornment, TextField, Checkbox, Box, Typography, Button, Divider, Snackbar, Alert, Grid, Select, MenuItem, FormControl, InputLabel, styled, Card, CircularProgress, Backdrop, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, FormGroup, FormControlLabel } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import Iconify from '../../../components/iconify';
import { API, Auth, graphqlOperation } from 'aws-amplify';
import { GRAPHQL_AUTH_MODE } from '@aws-amplify/api';
import SnackbarAlert from 'src/components/snackbar-alert/SnackbarAlert';
import * as queries from '../../../graphql/queries';
import * as customqueries from '../../../graphql/customqueries';
import { DatePicker } from '@mui/x-date-pickers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDiscord, faFacebook, faTwitch, faYoutube } from '@fortawesome/free-brands-svg-icons';
import GoogleAutoComplete from 'src/components/google-auto-complete/GoogleAutoComplete';
import { Check, Edit, Mail, SportsEsports, YouTube } from '@mui/icons-material';
import { addDays, format } from 'date-fns';
import { useAuthContext } from 'src/auth/useAuthContext';
import keyToImageUrl from 'src/utils/keyToImageUrl';

// ----------------------------------------------------------------------

const verifyZipCode = (userProviderId, fiberHomesResults) => {
  const providerVerified = fiberHomesResults.some((fhProviderObj) => {
    return fhProviderObj.multimarket_id === userProviderId;
  });
  return providerVerified
}

const ProviderImages = memo(({ providerLogo }) => {
  const Logo = styled('img')({
    width: '100%',
    height: 'auto',
    maxHeight: '150px',
    maxWidth: '200px',
    objectFit: 'contain'
  });

  return (

    <>
      {!providerLogo &&
        <Grid container sx={{ mb: { xs: 20, md: 20 } }} justifyContent={'center'}>
          <Grid item xs={12} md={8}>
          </Grid>
        </Grid>
      }
      {providerLogo &&
        <Grid container sx={{ mb: { xs: 5, md: 10 }, mt: { xs: 10, md: 0 } }} justifyContent={'center'}>
          <Grid item>
            <Logo src={providerLogo} onError={(elm) => elm.currentTarget.style.display = 'none'} />
          </Grid>
        </Grid>
      }
    </>
  )
});

function SelectProvider({ setPartnerAccountNumber, setUserVerified, showSnackbar, setAddressSetWithProvider, setShowSnackbar, address, setAddress, provider, setProvider, setProviderLogo, handleSelectProvider, setFormErrors, formErrors, providers, loadingProviders }) {
  const [queryParams, setQueryParams] = useSearchParams();
  const [addressSelected, setAddressSelected] = useState(false);
  const [providerSelection, setProviderSelection] = useState(queryParams.has('provider') ? '' : null);
  const [disabled, setDisabled] = useState(true);
  const [loading, setLoading] = useState(false);
  const [providerId, setProviderId] = useState();
  const [useAccountNumber, setUseAccountNumber] = useState(false);
  const [noAccountPassword, setNoAccountPassword] = useState(false);
  const [accountNumber, setAccountNumber] = useState('');
  const [accountPassword, setAccountPassword] = useState('');
  const [accountNumberError, setAccountNumberError] = useState('');
  const [accountPasswordError, setAccountPasswordError] = useState('');
  const [noPlan, setNoPlan] = useState(false);

  const searchFiberHomes = (index) => {
    if (providers[index].useAccountNumber) {
      if (address && accountNumber && (accountPassword || noAccountPassword)) {
        setLoading(true)
        // TODO: Call an endpoint to check the creds and return packages
        API.post('fgnapi', '/checkPartnerAccountDetails', {
          body: {
            username: accountNumber,
            password: accountPassword,
            providerId
          }
        }).then(response => {
          console.log(response)
          if (response?.verified && response?.accountNumber) {
            if (response?.accountPlans) {
              handleSelectProvider(index)
              setAddressSetWithProvider(true)
              setPartnerAccountNumber(response.accountNumber)
            } else {
              setShowSnackbar({
                type: 'error',
                status: true,
                message: "Your plan doesn't cover Fiber Gaming Network."
              })
              setNoPlan(true)
            }
          } else {
            setAccountNumberError(true)
            setAccountPasswordError(true)
            setShowSnackbar({
              type: 'error',
              status: true,
              message: "Incorrect account number or password"
            })
          }
          setLoading(false)
        }).catch(error => {
          console.log(error)
          setLoading(false)
        })
      } else {
        setAccountNumberError(true)
        setAccountPasswordError(true)
        setFormErrors({
          ...formErrors,
          address: Boolean(!address)
        });
        setShowSnackbar({
          type: 'error',
          status: true,
          message: "Please fill all required fields."
        })
      }
    } else {
      if (address && address !== '') {

        if (providers[index].fiberHomesId) {
          console.log(providers[index].fiberHomesId)
          if (index !== 'noProvider') {
            setLoading(true);
            /* Pull the provider by it's ID in GraphQL */

            const selectedProvider = providers[index].id

            API.graphql({
              query: customqueries.getProviderProfile,
              variables: { id: selectedProvider },
              authMode: GRAPHQL_AUTH_MODE.API_KEY,
            }).then((response) => {
              const fiberHomesId = response.data.getProviderProfile.fiberHomesId

              /* If the fiberhomesID did not return null, pull the providers from FiberHomes that match the zipcode of the address they've selected */
              if (fiberHomesId) {
                fetch(`https://api.fiberhomes.com/v1/provider?key=3f30734ae9e63b4bfa36ee72111171743199e03d&zipcode=${address.zipCode}`, { method: 'GET' })
                  .then((response) => {
                    return response.json()
                  })
                  .then((data) => {
                    /* This function will check to see if the fiberhomes ID of one attached to their profile matches any that cover their zipcode. It returns true or false */
                    const zipIsVerified = verifyZipCode(fiberHomesId, data.providers);

                    if (zipIsVerified) {
                      /* The provider they selected did match with a provider within their zip code */
                      handleSelectProvider(index)
                      setAddressSetWithProvider(true)
                      setLoading(false);
                    } else {
                      // This means that the provider they selected did not match the zip code they provided
                      // TODO: Popup letting the user know that they are not a customer of their selected provider
                      setLoading(false);
                      setShowSnackbar({
                        status: true,
                        type: 'error',
                        message: 'The internet provider does not match your address.'
                      })
                    }
                  })
                  .catch((error) => {
                    console.log(error);
                  });
              } else {
                // This would mean that there is not an ID attached to their selected provider. For now we will move along, as we can't verify the providers coverage.
                handleSelectProvider(index)
                setAddressSetWithProvider(true)
                setLoading(false);
              }
            }).catch(err => console.log(err))
          } else {
            handleSelectProvider(index)
            setAddress(null)
            setLoading(false);
          }
        } else {
          console.log(JSON.parse(providers[index].zipcodes))
          if (providers[index].zipcodes && providers[index].zipcodes !== '' && JSON.parse(providers[index].zipcodes)[0] !== '') {
            const code = JSON.parse(providers[index].zipcodes)
            if (code.includes(address.zipCode)) {
              handleSelectProvider(index)
              setAddressSetWithProvider(true)
              setLoading(false);
            } else {
              setLoading(false);
              setShowSnackbar({
                status: true,
                type: 'error',
                message: 'The internet provider does not match your address.'
              })
            }
          } else {
            handleSelectProvider(index)
            setAddressSetWithProvider(true)
            setLoading(false);
          }
        }
      } else {
        setFormErrors({
          ...formErrors,
          address: true
        });
        setShowSnackbar({
          type: 'error',
          status: true,
          message: "Please choose an address."
        })
      }
    }
  }

  const handleProviderSelection = (selection) => {
    if (providers[selection]?.externalAuth) {
      setUseAccountNumber(false)
      Auth.federatedSignIn({ customProvider: providers[selection].externalAuth }).then(() => {
        // Login was successful
      }).catch(err => {
        // An error occurred
        console.error(err);
        console.log(err);
        console.log(err.response);
        // Here, err should have information about the error that occurred.
        // Depending on the error, err could be a string error message or an object with more details.
      });
    } else if (providers[selection]?.useAccountNumber) {
      setUseAccountNumber(true)
      if (providers[selection]?.noAccountPassword) {
        setNoAccountPassword(true)
      } else {
        setNoAccountPassword(false)
      }
      console.log(selection)
      setProviderSelection(selection);
      setProviderId(providers[selection]?.id)
    } else {
      setUseAccountNumber(false)
      setNoAccountPassword(false)
      console.log(selection)
      setProviderSelection(selection);
      if (selection === 'noProvider') {
        handleSelectProvider(selection)
        setAddress(null)
      }
    }
  }

  useEffect(() => {
    if (address) {
      setAddressSelected(true);
    }
  }, [address]);

  useEffect(() => {
    if (queryParams.has('provider') && providers) {
      const newSelectedProvider = providers.findIndex(providerObj => providerObj.slug === queryParams.get('provider'));
      handleProviderSelection(newSelectedProvider)
      setDisabled(false);
    }
  }, [providers])

  const setProviderLogoFunction = async () => {
    const newSelectedProvider = providers.findIndex(providerObj => providerObj.slug === queryParams.get('provider'));
    keyToImageUrl(providers[newSelectedProvider].images).then(response => {
      setProviderLogo(response);
    }).catch(err => {
      console.log(err);
      setProviderLogo(providers[newSelectedProvider].images)
    })
  }

  useEffect(() => {
    if (!loadingProviders && providers.length > 1 && queryParams.has('provider')) {
      setProviderLogoFunction();
    }
  }, [loadingProviders, providers])

  return (
    <>
      <Typography variant="h4" textAlign={'center'} gutterBottom>
        {!queryParams.has('provider') ? "Choose Your Internet Provider" : `Enter Your ${useAccountNumber ? 'Information' : 'Home Address'}`}
      </Typography>
      <Grid container mt={0} spacing={5} justifyContent={'center'}>
        {!queryParams.has('provider') &&
          <Grid item xs={12}>
            <FormControl
              fullWidth
              disabled={loadingProviders}
            >
              <InputLabel error={formErrors.provider} id="demo-simple-select-label">{loadingProviders ? 'Loading Internet Providers...' : 'Choose Your Internet Provider'}</InputLabel>

              <Select
                fullWidth
                label={loadingProviders ? 'Loading Internet Providers...' : 'Choose Your Internet Provider'}
                variant='outlined'
                value={providerSelection}
                error={formErrors.provider}
                onChange={(event) => {
                  handleProviderSelection(event.target.value);
                  setDisabled(false);
                  setFormErrors({ ...formErrors, provider: false });
                  if (event.target.value !== "noProvider") {
                    setProviderLogo(providers[event.target.value].images);
                  } else {
                    setProviderLogo('')
                  }
                }}
              >

                {(providers?.length > 0) &&
                  providers.map((provider, index) =>
                    <MenuItem key={index} value={index}>
                      {provider.name}
                    </MenuItem>
                  )
                }
                {/* <MenuItem value='noProvider'>
                  Provider Not Listed
                </MenuItem> */}
              </Select>
            </FormControl>
          </Grid>
        }
        {providerSelection !== null &&
          <Grid item xs={12}>
            <GoogleAutoComplete address={address} setAddress={setAddress} formErrors={formErrors} setFormErrors={setFormErrors} />
          </Grid>
        }
        {useAccountNumber &&
          <>
            <Grid item xs={12}>
              <TextField
                label='Account Number'
                error={accountNumberError}
                fullWidth
                required
                value={accountNumber}
                onChange={(event) => {
                  setAccountNumber(event.target.value)
                  setAccountNumberError(false)
                  setNoPlan(false)
                }}
              />
            </Grid>
            {!noAccountPassword &&
              <Grid item xs={12}>
                <TextField
                  label='Account Password'
                  fullWidth
                  required
                  error={accountPasswordError}
                  type='password'
                  value={accountPassword}
                  onChange={(event) => {
                    setAccountPassword(event.target.value)
                    setAccountPasswordError(false)
                    setNoPlan(false)
                  }}
                />
              </Grid>
            }
          </>
        }

        {noPlan &&
          <Grid item xs={12}>
            <Typography fontSize={14} color='error' textAlign='center'>
              Your account does not have a valid plan.<br />Please contact <b>{providers?.[providerSelection]?.name}</b> for more details.
            </Typography>
          </Grid>
        }

        <Grid item xs={12} sm={12}>
          <LoadingButton sx={{ paddingY: 1.5, fontSize: 17 }} loading={loading} disabled={disabled} onClick={() => { searchFiberHomes(providerSelection) }} size='large' fullWidth variant='contained'>
            Continue
          </LoadingButton>
        </Grid>
      </Grid>
      {!(queryParams.has('event') && queryParams.get('event').toLowerCase() === 'fgnpreview') && !queryParams.has('provider') &&
        <Grid container mt={4} justifyContent={'center'}>
          <Grid item xs={12}>
            <Button variant='text' fullWidth color='primary' onClick={() => { setProvider(''); setProviderLogo(''); setUserVerified('signupSchool'); setAddress(); }} size='large'>
              Click here to sign up with invite code
            </Button>
          </Grid>
        </Grid>
      }
      <Backdrop
        sx={{ color: '#fff', zIndex: 3000 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
        <Typography variant='body1' ml={3}>
          Verifying Provider...
        </Typography>
      </Backdrop>
    </>
  )
}

function SelectSchool({ setUserVerified, inviteCode, setInviteCode, showSnackbar, setAddressSetWithProvider, setShowSnackbar, address, setAddress, provider, setProvider, setProviderLogo, handleSelectProvider, setFormErrors, formErrors, providers, loadingProviders }) {
  // const [schoolSelection, setSchoolSelection] = useState(null);
  // const [addressSelected, setAddressSelected] = useState(false);
  // const [promoCode, setPromoCode] = useState('');
  // const [promoMessage, setPromoMessage] = useState('');
  // const [disabled, setDisabled] = useState(true);
  // const [loading, setLoading] = useState(false);

  // var staticSchools = [
  //   {
  //     "code": "ABC123",
  //     "name": "Alpha Beta Charlie School",
  //     "provider": "Partner Bros ISP"
  //   },
  //   {
  //     "code": "XYZ789",
  //     "name": "Xylo Zebra School",
  //     "provider": "Netfinity ISP"
  //   },
  //   {
  //     "code": "LMN456",
  //     "name": "Lima Mike November School",
  //     "provider": "Speedster ISP"
  //   },
  //   {
  //     "code": "GHI111",
  //     "name": "Golf Hotel India School",
  //     "provider": "WebConnect ISP"
  //   },
  //   {
  //     "code": "DEF567",
  //     "name": "Delta Echo Foxtrot School",
  //     "provider": "Skyline ISP"
  //   },
  //   {
  //     "code": "JKL321",
  //     "name": "Juliet Kilo Lima School",
  //     "provider": "StreamFast ISP"
  //   },
  //   {
  //     "code": "PQR888",
  //     "name": "Papa Quebec Romeo School",
  //     "provider": "GigaNet ISP"
  //   },
  //   {
  //     "code": "STU234",
  //     "name": "Sierra Tango Uniform School",
  //     "provider": "FiberLink ISP"
  //   },
  //   {
  //     "code": "UVW999",
  //     "name": "Uniform Victor Whiskey School",
  //     "provider": "QuickServe ISP"
  //   },
  //   {
  //     "code": "NOP654",
  //     "name": "November Oscar Papa School",
  //     "provider": "WideWeb ISP"
  //   }
  // ];

  // // Sets school selection based on the dropdown menu
  // const handleSchoolSelection = (selection) => {
  //   setSchoolSelection(selection);
  //   setDisabled(false);
  // };

  // // Verifies the promo code for the school
  // // const verifySchool = (index) => {
  // //   setLoading(true);
  // //   var selectedSchool = staticSchools[index];

  // //   if (selectedSchool && selectedSchool.code === promoCode) {
  // //     setPromoMessage("Promo code matches!");
  // //     handleSelectSchool(index);
  // //   } else {
  // //     setPromoMessage("Promo code does not match.");
  // //   }
  // //   setLoading(false);
  // // };

  // // Sets and verifies the address
  // useEffect(() => {
  //   if (address) {
  //     setAddressSelected(true);
  //   }
  // }, [address]);

  // return (
  //   <>
  //     <Typography variant="h4" textAlign={'center'} gutterBottom>
  //       Sign Up With Code
  //     </Typography>
  //     <Grid container mt={0} spacing={3} justifyContent={'center'}>
  //       {/* <Grid item xs={12}>
  //         <FormControl fullWidth>
  //           <InputLabel error={formErrors.school} id="demo-simple-select-label">Choose Your School</InputLabel>
  //           <Select
  //             fullWidth
  //             label='Choose Your School'
  //             variant='outlined'
  //             value={schoolSelection}
  //             error={formErrors.school}
  //             onChange={(event) => {
  //               handleSchoolSelection(event.target.value);
  //               setFormErrors({ ...formErrors, school: false });
  //             }}
  //           >
  //             {(staticSchools?.length > 0) &&
  //               staticSchools.map((school, index) =>
  //                 <MenuItem key={index} value={index}>
  //                   {school.name}
  //                 </MenuItem>
  //               )
  //             }
  //           </Select>
  //         </FormControl>
  //       </Grid> */}
  //         <Grid item xs={12}>
  //           <GoogleAutoComplete address={address} setAddress={setAddress} formErrors={formErrors} setFormErrors={setFormErrors} />
  //         </Grid>
  //         <Grid item xs={12}>
  //           <TextField
  //             label="Promo Code"
  //             value={promoCode}
  //             onChange={e => setPromoCode(e.target.value)}
  //             margin="normal"
  //             fullWidth
  //             variant="outlined"
  //           />
  //         </Grid>
  //       <Grid item xs={12} sm={12}>
  //         <LoadingButton sx={{ paddingY: 1.5, fontSize: 17 }} loading={loading} disabled={disabled} onClick={() => { verifySchool(schoolSelection) }} size='large' fullWidth variant='contained'>
  //           Continue
  //         </LoadingButton>
  //       </Grid>
  //       <Grid item xs={12}>
  //         <Typography variant="body2" textAlign={'center'} color="secondary">
  //           {promoMessage}
  //         </Typography>
  //       </Grid>
  //     </Grid>

  //     <Backdrop
  //       sx={{ color: '#fff', zIndex: 3000 }}
  //       open={loading}
  //     >
  //       <CircularProgress color="inherit" />
  //       <Typography variant='body1' ml={3}>
  //         Verifying School...
  //       </Typography>
  //     </Backdrop>
  //   </>

  // );

  const [queryParams, setQueryParams] = useSearchParams();
  const [addressSelected, setAddressSelected] = useState(false);
  const [providerSelection, setProviderSelection] = useState(queryParams.has('provider') ? '' : null);
  const [disabled, setDisabled] = useState(true);
  const [loading, setLoading] = useState(false);
  const [promoCode, setPromoCode] = useState('');
  const [promoCodeError, setPromoCodeError] = useState(false);

  useEffect(() => {
    if (addressSelected && promoCode) {
      setDisabled(false)
    } else {
      setDisabled(true)
    }
  }, [addressSelected, promoCode])

  const checkForErrors = () => {
    let addressError;
    let promoError;

    if (!address || address === '') {
      addressError = true

      setFormErrors({
        ...formErrors,
        address: true
      });
    }

    if (!promoCode || promoCode === '') {
      promoError = true

      setPromoCodeError(true)
    }

    if (promoError || addressError) {
      return false
    }

    return true
  }

  const searchFiberHomes = () => {
    if (checkForErrors()) {
      setLoading(true)
      API.post('fgnapi', '/confirmInvite', {
        body: {
          inviteCode: promoCode
        }
      }).then(response => {
        console.log(response)
        if (response.message) {
          setLoading(false);
          setShowSnackbar({
            status: true,
            type: 'error',
            message: response.message
          })
          setPromoCodeError(true)
        } else if (response.providerId) {
          // This is where we need to set the provider
          const providerIndex = providers.findIndex(obj => obj.id === response.providerId)
          handleSelectProvider(providerIndex)
          setAddressSetWithProvider(true)
          setLoading(false);
          setProviderSelection(providerIndex);
          setProviderLogo(providers[providerIndex].images);
          setInviteCode(promoCode)
        } else {
          setLoading(false);
          setShowSnackbar({
            status: true,
            type: 'error',
            message: 'Something went wrong. Please try again.'
          })
        }
      }).catch(error => {
        console.log(error)
        setLoading(false);
      })


      // console.log(JSON.parse(providers[index].zipcodes))
      // if (providers[index].zipcodes && providers[index].zipcodes !== '' && JSON.parse(providers[index].zipcodes)[0] !== '') {

      // This should happen if there's an issue with the code.

      // const code = JSON.parse(providers[index].zipcodes)
      // if (code.includes(address.zipCode)) {
      //   handleSelectProvider(index)
      //   setAddressSetWithProvider(true)
      //   setLoading(false);
      // } else {
      //   setLoading(false);
      //   setShowSnackbar({
      //     status: true,
      //     type: 'error',
      //     message: 'The internet provider does not match your address.'
      //   })
      // }


      // } else {

      // This should be where we set the provider

      // handleSelectProvider(index)
      // setAddressSetWithProvider(true)
      // setLoading(false);


      //   }
      // } else {

      // This means something wasn't filled out.
      // setShowSnackbar({
      //   type: 'error',
      //   status: true,
      //   message: "Please check for errors."
      // })
    }
  }


  const handleProviderSelection = (selection) => {
    console.log(selection)
    setProviderSelection(selection);

    if (selection === 'noProvider') {
      handleSelectProvider(selection)
      setAddress(null)
    }
  }

  useEffect(() => {
    if (address) {
      setAddressSelected(true);
    }
  }, [address]);

  const setProviderLogoFunction = async () => {
    const newSelectedProvider = providers.findIndex(providerObj => providerObj.slug === queryParams.get('provider'));
    keyToImageUrl(providers[newSelectedProvider].images).then(response => {
      setProviderLogo(response);
    }).catch(err => {
      console.log(err);
      setProviderLogo(providers[newSelectedProvider].images)
    })
  }

  useEffect(() => {
    if (!loadingProviders && providers.length > 1 && queryParams.has('provider')) {
      setProviderLogoFunction();
    }
  }, [loadingProviders, providers])

  return (
    <>
      <Typography variant="h4" textAlign={'center'} gutterBottom>
        Sign Up With Invite Code
      </Typography>
      <Grid container mt={0} spacing={3} justifyContent={'center'}>
        <Grid item xs={12}>
          <GoogleAutoComplete address={address} setAddress={setAddress} formErrors={formErrors} setFormErrors={setFormErrors} />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Invite Code"
            value={promoCode}
            error={promoCodeError}
            onChange={e => {
              setPromoCodeError(false)
              setPromoCode(e.target.value.replace(/[^a-zA-Z0-9-_]/g, '').toUpperCase())
            }}
            margin="normal"
            fullWidth
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <LoadingButton sx={{ paddingY: 1.5, fontSize: 17 }} loading={loading} disabled={disabled} onClick={() => { searchFiberHomes(providerSelection) }} size='large' fullWidth variant='contained'>
            Continue
          </LoadingButton>
        </Grid>
      </Grid>
      <Grid container mt={4} justifyContent={'center'}>
        <Grid item xs={12}>
          <Button variant='text' fullWidth color='primary' onClick={() => { setProvider(''); setInviteCode(); setPromoCode(); setProviderLogo(''); setUserVerified('signupProvider'); setAddress(); }} size='large'>
            Click here to sign up with provider
          </Button>
        </Grid>
      </Grid>
      {/* {!(queryParams.has('event') && queryParams.get('event').toLowerCase() === 'fgnpreview') && !queryParams.has('provider') &&
        <Grid container mt={4} justifyContent={'center'}>
          <Grid item xs={12}>
            <Button variant='text' fullWidth color='primary' onClick={() => { setProvider('noProvider'); setProviderLogo('') }} size='large'>
              Internet Provider not listed? Click here.
            </Button>
          </Grid>
        </Grid>
      } */}
      <Backdrop
        sx={{ color: '#fff', zIndex: 3000 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
        <Typography variant='body1' ml={3}>
          Verifying Invite Code...
        </Typography>
      </Backdrop>
    </>
  )
}


function UserInformationForm({ termsOfServiceError, setTermsOfServiceError, usernameExist, setTermsOfService, setPrivacyPolicy, termsOfService, privacyPolicy, addressSetWithProvider, signUpFormVerificationErrors, setSignUpFormVerificationErrors, providerName, providerConfirmed, address, setAddress, signupOnly, username, setUsername, handleNextStep, email, setEmail, password, setPassword, showPassword, setShowPassword, verifiedPassword, setVerifiedPassword, formErrors, setFormErrors, loading }) {
  const navigate = useNavigate();
  const [queryParams, setQueryParams] = useSearchParams();

  return (
    <>
      {!signupOnly &&
        <>
          <Typography variant="h4" textAlign={signupOnly ? 'center' : 'left'} gutterBottom>
            Sign up
          </Typography>
          <Typography variant="body2" sx={{ mb: 2 }}>
            Already have an account?
            <Link href='/login' variant="subtitle2">Log-in</Link>
          </Typography>
        </>
      }
      {signupOnly &&
        <Typography variant="h5" textAlign='center' gutterBottom mb={5}>
          Let's set up your free account.
        </Typography>
      }
      {!signupOnly &&
        <Divider sx={{ my: 3 }}>

          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            OR
          </Typography>

        </Divider>
      }
      <form onSubmit={(event) => {
        event.preventDefault();
        return false;
      }}>
        <Stack spacing={3}>
          <TextField
            name="username"
            label="Username"
            type='username'
            inputProps={{
              id: 'username'
            }}
            value={username}
            autoComplete='username'
            error={formErrors.username || signUpFormVerificationErrors.username}
            helperText={signUpFormVerificationErrors.username ? 'Username can only contain 35 alphanumeric characters' : null}
            onChange={(event) => {
              setFormErrors({ ...formErrors, username: false });
              setSignUpFormVerificationErrors({ ...signUpFormVerificationErrors, username: false });
              setUsername(event.target.value)
            }}
            onKeyDown={(event) => {
              if (event.key === 'Enter') {
                handleNextStep();
              }
            }}
          />

          <TextField
            name="email"
            label="Email address"
            type='email'
            value={email}
            autoComplete='email'
            error={formErrors.email || signUpFormVerificationErrors.email}
            inputProps={{
              id: 'email',
              type: 'email'
            }}
            helperText={signUpFormVerificationErrors.email ? 'Invalid email address' : null}
            onChange={(event) => {
              setFormErrors({ ...formErrors, email: false });
              setSignUpFormVerificationErrors({ ...signUpFormVerificationErrors, email: false });
              setEmail(event.target.value)
            }}
            onKeyDown={(event) => {
              if (event.key === 'Enter') {
                handleNextStep()
              }
            }}
          />

          <TextField
            name="new-password"
            label="Password"
            type={showPassword ? 'text' : 'password'}
            autoComplete='new-password'
            value={password}
            error={formErrors.password || signUpFormVerificationErrors.password}
            helperText={signUpFormVerificationErrors.password ? 'Password must be between 8 and 256 characters long.' : null}
            onChange={(event) => {
              setFormErrors({ ...formErrors, password: false });
              setSignUpFormVerificationErrors({ ...signUpFormVerificationErrors, password: false });
              setPassword(event.target.value)
            }}
            onKeyDown={(event) => {
              if (event.key === 'Enter') {
                handleNextStep();
              }
            }}
            InputProps={{
              id: 'new-password-text-field',
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                    <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <TextField
            name="confirm-password-text-field"
            label="Verify Password"
            type='password'
            autoComplete='new-password'
            value={verifiedPassword}
            error={formErrors.password || signUpFormVerificationErrors.password}
            inputProps={{
              id: 'confirm_password'
            }}
            helperText={(signUpFormVerificationErrors.password === true) ? 'Password must be between 8 and 256 characters long.' : null}
            onChange={(event) => {
              setFormErrors({ ...formErrors, password: false });
              setSignUpFormVerificationErrors({ ...signUpFormVerificationErrors, password: false });
              setVerifiedPassword(event.target.value)
            }}
            onKeyDown={(event) => {
              if (event.key === 'Enter') {
                handleNextStep();
              }
            }}
          />
          {!addressSetWithProvider &&
            <GoogleAutoComplete address={address} setAddress={setAddress} formErrors={formErrors} setFormErrors={setFormErrors} />
          }
        </Stack>
        <FormGroup sx={{ mt: 2.5 }}>
          <FormControlLabel
            required
            control={
              <Checkbox
                onChange={(event) => {
                  setTermsOfService(event.target.checked);
                  setTermsOfServiceError(event.target.checked);
                }}
              />
            }
            label={
              <Typography variant='body2' color={termsOfServiceError ? null : 'error'}>
                I agree to the <Link target='_BLANK' href="/FGNTermsofService.html">Terms of Service</Link> and <Link target='_BLANK' href="/FGNPrivacyPolicy.html">Privacy Policy</Link>
              </Typography>

            }
          />
        </FormGroup>
        <LoadingButton loading={loading} fullWidth size="large" type="submit" variant="contained" sx={{ marginTop: 3, paddingY: 1.5, fontSize: 17 }} onClick={() => {
          handleNextStep();
        }}>
          Sign Up
        </LoadingButton>
        {usernameExist &&
          <Typography variant='body1' textAlign={'center'} mt={5}>
            <Link variant='body1' onClick={() => { navigate(`/login`) }} sx={{ cursor: 'pointer' }}>
              Already have an account? Click here to log in.
            </Link>
          </Typography>
        }
      </form>
    </>
  )
}

function LogInToVerifyForm({ setUserVerified, setShowSnackbar, profileData, verificationError, username, showPassword, password, formErrors, setFormErrors, setPassword, handleVerification, setShowPassword, loading }) {
  const navigate = useNavigate();

  const handleResendVerification = () => {
    Auth.resendSignUp(JSON.parse(profileData).userName).then(() => {
      setUserVerified('verify')
    }).catch(err => console.log(err));
  }

  const checkPassword = () => {
    if (password === '') {
      setFormErrors({
        ...formErrors,
        password: true
      });
      setShowSnackbar({
        type: 'error',
        message: 'Please enter password to verify.',
        status: true
      })
    } else {
      handleVerification();
    }
  }

  return (
    <>
      <Typography variant='h4' textAlign={'center'} mb={5}>
        Log In to Complete Verification
      </Typography>
      <Typography variant='body1' textAlign={'center'} mb={5}>
        Please enter username and password to verify your account
      </Typography>
      <form onSubmit={(event) => {
        event.preventDefault();
        return false;
      }}>
        <Stack spacing={5} mt={5}>
          <TextField
            name="username"
            label="Username"
            type='text'
            autoComplete='username'
            inputProps={{
              id: 'username'
            }}
          />
          <TextField
            name="password"
            label="Password"
            id='password'
            autoComplete='password'
            type={showPassword ? 'text' : 'password'}
            value={password}
            error={formErrors.password}
            onChange={(event) => {
              setFormErrors({ ...formErrors, password: false });
              setPassword(event.target.value)
            }}
            onKeyDown={(event) => {
              if (event.key === 'Enter') {
                checkPassword();
              }
            }}
            InputProps={{
              id: 'password',
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                    <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Stack>
        <LoadingButton loading={loading} fullWidth size="large" type="submit" variant="contained" sx={{ marginTop: 5, paddingY: 1.5, fontSize: 17 }} onClick={checkPassword}>
          Verify Account
        </LoadingButton>
      </form>
      {(verificationError === 'invalid' || verificationError === 'expired') &&
        <Typography variant='body2' textAlign={'center'} mt={5}>
          <Link variant='body2' sx={{ cursor: 'pointer' }} onClick={() => { handleResendVerification() }}>
            Click here to resend verification link
          </Link>
        </Typography>
      }
    </>
  )
}



export default function SignUpForm({ verified = 'start', user = '', signupOnly = false, inviteCode = '', profileData = null, code = null, discordId = '', discordUsername = '', discordFullDetails = null }) {
  const navigate = useNavigate();
  const { login } = useAuthContext();
  const [queryParams, setQueryParams] = useSearchParams();
  const [providers, setProviders] = useState('');
  const [loadingProviders, setLoadingProviders] = useState(true);
  const [provider, setProvider] = useState('');
  const [providerName, setProviderName] = useState();
  const [providerConfirmed, setProviderConfirmed] = useState(false);
  const [school, setSchool] = useState('');
  const [schoolName, setSchoolName] = useState();
  const [schoolConfirmed, setSchoolConfirmed] = useState(false);
  var staticSchools = [
    // {
    //     "code": "ABC123",
    //     "school": "Alpha Beta Charlie School",
    //     "provider": "Partner Bros ISP"
    // },
    // {
    //     "code": "XYZ789",
    //     "school": "Xylo Zebra School",
    //     "provider": "Netfinity ISP"
    // },
    // {
    //     "code": "LMN456",
    //     "school": "Lima Mike November School",
    //     "provider": "Speedster ISP"
    // },
    // {
    //     "code": "GHI111",
    //     "school": "Golf Hotel India School",
    //     "provider": "WebConnect ISP"
    // },
    // {
    //     "code": "DEF567",
    //     "school": "Delta Echo Foxtrot School",
    //     "provider": "Skyline ISP"
    // },
    // {
    //     "code": "JKL321",
    //     "school": "Juliet Kilo Lima School",
    //     "provider": "StreamFast ISP"
    // },
    // {
    //     "code": "PQR888",
    //     "school": "Papa Quebec Romeo School",
    //     "provider": "GigaNet ISP"
    // },
    // {
    //     "code": "STU234",
    //     "school": "Sierra Tango Uniform School",
    //     "provider": "FiberLink ISP"
    // },
    // {
    //     "code": "UVW999",
    //     "school": "Uniform Victor Whiskey School",
    //     "provider": "QuickServe ISP"
    // },
    // {
    //     "code": "NOP654",
    //     "school": "November Oscar Papa School",
    //     "provider": "WideWeb ISP"
    // }
  ];
  const [typeFormUserInfo, setTypeFormUserInfo] = useState();
  const [typeFormProfileInfo, setTypeFormProfileInfo] = useState();
  const [typeFormButtonLoading, setTypeFormButtonLoading] = useState(true);
  const [termsOfService, setTermsOfService] = useState(false);
  const [privacyPolicy, setPrivacyPolicy] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [birthdate, setBirthdate] = useState(null);
  const [providerLogo, setProviderLogo] = useState();
  const [showPassword, setShowPassword] = useState(false);
  const [username, setUsername] = useState(user);
  const [password, setPassword] = useState('');
  const [verifiedPassword, setVerifiedPassword] = useState('');
  const [usernameExist, setUsernameExist] = useState(false);
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [userVerified, setUserVerified] = useState(verified);
  const [verificationCode, setVerificationCode] = useState('');
  const [verificationError, setVerificationError] = useState(false);
  const [verificationCodeLoading, setVerificationCodeLoading] = useState(false);
  const [disableInputs, setDisableInputs] = useState(false);
  const [verificationButtonText, setVerificationButtonText] = useState('Verify');
  const [address, setAddress] = useState();
  const [addressSetWithProvider, setAddressSetWithProvider] = useState(false);
  const [discordConfirm, setDiscordConfirm] = useState(false);
  const [userInviteCode, setUserInviteCode] = useState(null);
  const [termsOfServiceError, setTermsOfServiceError] = useState(true);
  const [signUpFormVerificationErrors, setSignUpFormVerificationErrors] = useState({
    username: false,
    email: false,
    password: false,
    address: false,
  });
  const [profileInfoVerificationErrors, setProfileInfoVerificationErrors] = useState({
    firstName: false,
    secondName: false
  });
  const [formErrors, setFormErrors] = useState({
    username: false,
    email: false,
    password: false,
    birthdate: false,
    address: false,
    discord: false,
    provider: false
  });
  const [showSnackbar, setShowSnackbar] = useState({ status: false, type: 'success', message: '' });
  const [partnerAccountNumber, setPartnerAccountNumber] = useState();

  const handleAuthSignUp = () => {
    /* This function is what's used to create the initial cognito user */
    setLoading(true)
    setDisableInputs(true)
    Auth.signUp({
      username,
      password,
      attributes: {
        address: address.fullAddress,
        email,
        'custom:provider': provider,
        'custom:zip_code': address.zipCode,
        ...(userInviteCode && { 'custom:invite_code': userInviteCode }),
        ...(partnerAccountNumber && { 'custom:account_number': partnerAccountNumber })
      }
    })
      .then((response) => {
        setShowSnackbar({
          type: 'success',
          message: 'Verification email sent!',
          status: true
        });
        setLoading(false);
        setDisableInputs(false);
        setUsername(response.user.username);
        window.location = `/verify?page=verify&username=${username}`;
      })
      .catch((err) => {
        if (err.name === "UsernameExistsException") {
          setShowSnackbar({
            type: 'error',
            message: 'This username is taken. Please try again.',
            status: true
          });
          setFormErrors({
            ...formErrors,
            username: true
          })
          setLoading(false);
          setDisableInputs(false);
          setUsernameExist(true);
        }
        console.log(err);
      });
  };

  const createUserProfile = (providerVerified = false) => {
    setLoading(true);
    setDisableInputs(true);
    /* This will stop the userdata from attempting to pair the user with a provider if they chose no provider or provider not listed. */
    const userData = {
      discordUsername
    }

    console.log(userData);
    console.log(providerVerified);
    /*  THIS IS WHERE THE FORM INFORMATION SHOULD BE ADDED TO THE COGNITO USER  */
    console.log(username)
    API.post('fgnapi', '/user/details/completeRegistration', {
      body: {
        firstName,
        lastName,
        birthdate: new Date(birthdate).toISOString().split('T')[0]
      }
    }).then(response => {
      console.log(response)
      window.location = "/register?page=complete"
    }).catch(error => {
      console.log(error)
      console.log(error.response)
    })
  }

  const searchFiberHomes = (providerId) => {
    /* Pull the provider by it's ID in GraphQL */
    console.log(address)
    console.log(username)
    API.graphql({
      query: customqueries.getProviderProfile,
      variables: { id: providerId },
      authMode: GRAPHQL_AUTH_MODE.API_KEY,
    }).then((response) => {
      const fiberHomesId = response.data.getProviderProfile.fiberHomesId

      /* If the fiberhomes did not return null, pull the providers from FiberHomes that match the zipcode of the address they've selected */
      if (fiberHomesId) {
        fetch(`https://api.fiberhomes.com/v1/provider?key=3f30734ae9e63b4bfa36ee72111171743199e03d&zipcode=${address.zipCode}`, { method: 'GET' })
          .then((response) => {
            return response.json()
          })
          .then((data) => {
            /* This function will check to see if the fiberhomes ID of one attached to their profile matches any that cover their zipcode. It returns true or false */
            const zipIsVerified = verifyZipCode(fiberHomesId, data.providers);

            if (zipIsVerified) {
              /* The provider they selected did match with a provider within their zip code */
              createUserProfile(true);
            } else {
              // This means that the provider they selected did not match the zip code they provided
              // TODO: Popup letting the user know that they are not a customer of their selected provider
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        // This would mean that there is not an ID attached to their selected provider. For now we will move along, as we can't verify the providers coverage.
        createUserProfile(true);
      }
    }).catch(err => console.log(err))
  }

  function isOver13(dateOfBirth) {
    const thirteenYearsAgo = new Date();
    thirteenYearsAgo.setFullYear(thirteenYearsAgo.getFullYear() - 13);

    const dob = new Date(dateOfBirth);
    const year = dob.getFullYear();
    const month = (dob.getMonth() + 1).toString().padStart(2, '0');
    const day = dob.getDate().toString().padStart(2, '0');
    const dobFormatted = `${year}-${month}-${day}`;

    return dobFormatted <= thirteenYearsAgo.toISOString().slice(0, 10);
  }

  const handleSignUp = () => {
    if (isOver13(birthdate)) {
      const areInputsBlank = (firstName !== '' && lastName !== '' && birthdate !== '');
      const areInputsValid = validateEntry('profileInfo');
      if (areInputsBlank && areInputsValid) {
        /* This will make sure all forms are filled out, and pull the provider from the authenticated user */
        createUserProfile();
      } else {
        setFormErrors({
          firstName: (firstName === ''),
          lastName: (lastName === ''),
          birthdate: (birthdate === ''),
          address: (address === ''),
          provider: (provider === '')
        });
        setShowSnackbar({
          type: 'error',
          message: `Please complete all fields ${!areInputsValid ? 'and fix errors ' : ''}to finish sign-up.`,
          status: true
        });
      }
    } else {
      setFormErrors({
        ...formErrors,
        birthdate: true
      });
      setShowSnackbar({
        type: 'error',
        message: `You must be over 13 to sign up.`,
        status: true
      });
    }
  }

  const replaceImagesWithUrls = async (imageStorageKeys) => {
    const imageUrls = await Promise.all(
      imageStorageKeys.map(async (obj) => {
        try {
          console.log(obj)
          const url = await keyToImageUrl(obj.images ? obj.images : obj.game?.images);
          return {
            ...obj,
            images: url
          };
        } catch (error) {
          console.log(`Error loading image '${obj.images}':`, error);
          return {
            ...obj,
            images: obj.images ? obj.images : obj.game?.images
          };
        }
      })
    );
    console.log(imageUrls)
    setProviders(imageUrls.filter(obj => obj.status === "active").sort((a, b) => a.name.localeCompare(b.name)));
  };

  const listProviders = () => {
    setLoadingProviders(true)
    async function fetchAllProviders() {
      let providers = [];
      let nextToken = null;
      let hasNextPage = true;

      while (hasNextPage) {
        const response = await API.graphql({
          query: customqueries.listProviderProfiles,
          authMode: GRAPHQL_AUTH_MODE.API_KEY,
          variables: { nextToken },

        });

        const { items, nextToken: newNextToken } = response.data.listProviderProfiles;

        providers = [...providers, ...items];
        nextToken = newNextToken;
        hasNextPage = Boolean(nextToken);
      }

      return providers;
    }

    const getProviders = async () => {
      try {
        const allProviders = await fetchAllProviders();

        replaceImagesWithUrls(allProviders);
        setLoadingProviders(false);

      } catch (err) {
        console.log(err);
      }
    };

    getProviders();

  }

  const handleClickOpen = () => {
    setDiscordConfirm(true);
  };

  const handleClickClose = () => {
    setDiscordConfirm(false);
  };

  const checkTermsOfService = () => {
    setTermsOfServiceError(termsOfService);
    return termsOfService;
  }

  const handleNextStep = () => {
    const validEntries = validateEntry('userInfo');
    const termsOfServiceChecked = checkTermsOfService();
    const areInputsBlank = (username !== '' && password !== '' && verifiedPassword !== '' && email !== '' && address && termsOfServiceChecked);
    if (areInputsBlank && validEntries) {
      if (password !== verifiedPassword) {
        setFormErrors({ ...formErrors, password: true });
        setShowSnackbar({
          type: 'error',
          message: 'Passwords do not match. Please try again.',
          status: true
        });
      } else {
        handleAuthSignUp();
      }
    } else {
      setFormErrors({
        username: (username === ''),
        email: (email === ''),
        password: (password === ''),
        address: (!address)
      });
      setShowSnackbar({
        type: 'error',
        message: `Please complete form${!validEntries ? ' and fix errors ' : ' '}to continue sign up.`,
        status: true
      });
    }
  }

  const handleSendVerification = () => {
    setDisableInputs(true);
    setLoading(true);
    Auth.resendSignUp(username).then((response) => {
      setLoading(false);
      setDisableInputs(false);
    }).catch((err) => {
      console.log(err);
    });
  }

  const handleVerificationCode = () => {
    setVerificationCodeLoading(true);
    setDisableInputs(true);
    Auth.confirmSignUp(username, verificationCode).then((response) => {
      setVerificationButtonText('Success! One last step...');
      setVerificationCodeLoading(false);
      setTimeout(() => {
        setUserVerified('profileInfo');
      }, 1000)
    }).catch((err) => console.log(err));
  }

  const handleSelectProvider = (index) => {
    if (index !== 'noProvider') {
      setProvider(providers[index].id);
      // setProviderLogo(providers[index].images);
      setProviderName(providers[index].name);
      setProviderConfirmed(true)
    } else {
      setProvider(index);
    }
  }

  const handleSelectSchool = (index) => {
    if (index !== null && index !== 'noSchool') {
      setSchool(staticSchools[index].code); // Assuming you're using the code to identify the school
      setSchoolName(staticSchools[index].school);
      setSchoolConfirmed(true);
    } else {
      setSchool(index);
      setSchoolConfirmed(false);
    }
  };



  const auth = async (username, password) => {
    try {
      await login(username, password);
    } catch (error) {
      console.log(error)
    }
  };

  const handleVerification = () => {
    setLoading(true)

    const profileObj = JSON.parse(profileData);

    console.log(profileObj.userName)

    Auth.confirmSignUp(profileObj.userName, code)
      .then((response) => {
        console.log(response)
        setUsername(profileObj.userName);
        Auth.signIn(profileObj.userName, password)
          .then((user) => {
            API.post('fgnapi', '/userDetails/confirmAccount', {
              body: {
                username: profileObj.userName
              }
            }).then(response => {
              auth(profileObj.userName, password).then(() => {
                setLoading(false)
                setUserVerified('profileInfo');
                // setUserInviteCode(user.attributes['custom:invite_code'])
                setShowSnackbar({
                  message: 'Verification Successful!',
                  type: 'success',
                  status: true
                });
              }).catch((err) => console.log(err))
            }).catch((err) => console.log(err))
          }).catch((err) => console.log(err))
      }).catch((err) => {
        console.log(err);
        if (err.message === 'User cannot be confirmed. Current status is CONFIRMED') {
          Auth.signIn(profileObj.userName, password)
            .then(() => {
              setLoading(false)
              setUserVerified('profileInfo');
              setShowSnackbar({
                message: 'Account Already Verified!',
                type: 'success',
                status: true
              });
              Auth.currentUserInfo().then((user) => {
                // setUserInviteCode(user.attributes['custom:invite_code'])
              }).catch(err => console.log(err));
            }).catch((err) => console.log(err))
        }
        if (err.name === 'CodeMismatchException') {
          setLoading(false);
          setShowSnackbar({
            status: true,
            type: 'error',
            message: 'Invalid verification link.'
          });
          setVerificationError('invalid');
        }
        if (err.name === 'ExpiredCodeException') {
          setLoading(false);
          setShowSnackbar({
            status: true,
            type: 'error',
            message: 'This verification link has expired.'
          });
          setVerificationError('expired');
        }
        if (err.name === 'NotAuthorizedException' && err.message !== 'User cannot be confirmed. Current status is CONFIRMED') {
          setLoading(false);
          setShowSnackbar({
            status: true,
            type: 'error',
            message: 'Incorrect password. Please try again.'
          });
          setFormErrors({
            ...formErrors,
            password: true
          })
          setLoading(false)
        }
      })

  }

  const validateEntry = (type) => {
    if (type === 'userInfo') {
      // Regular expression for validating an email address
      const emailRegex = /^\S+@\S+\.\S+$/;
      // Test the input email against the regular expression
      const emailTest = (!(emailRegex.test(email)));

      // Check to make sure the password is at least 6 characters long
      const passwordCheck = (password.length < 8 || password.length > 256);
      console.log(passwordCheck)
      // Set the response to a true or false bool based on that
      const passwordTest = passwordCheck;

      // Regular expression for validating a username
      // This makes sure there are only alphanumeric characters in the username
      const usernameRegex = /^[a-zA-Z0-9]+$/;
      // Test the input username against the regular expression
      const usernameTest = (!(usernameRegex.test(username) && username.length < 35));

      console.log(usernameTest)

      if (usernameTest || passwordTest || emailTest) {
        setSignUpFormVerificationErrors({
          username: usernameTest,
          password: passwordTest,
          email: emailTest
        })
        return false
      } else {
        return true
      }
    }
    if (type === 'profileInfo') {
      const checkName = (string) => {
        // Regular expression for validating a string
        const regex = /^[a-zA-Z]+$/;
        // Check if the length of the input string is at most 75 characters
        const isValidLength = string.length <= 50;
        // Test the input string against the regular expression
        const isValidCharacters = regex.test(string);
        // Return true if the string is valid
        return isValidLength && isValidCharacters;
      }

      const isFirstNameValid = checkName(firstName);
      const isLastNameValid = checkName(lastName);

      if (isFirstNameValid && isLastNameValid) {
        console.log('NAME IS VALID')
        return true
      } else {
        setProfileInfoVerificationErrors({
          firstName: (!isFirstNameValid),
          lastName: (!isLastNameValid)
        })
        console.log({
          firstName: (!isFirstNameValid),
          lastName: (!isLastNameValid)
        })
        return false
      }

    }
  }

  const getDiscordLoginLink = () => {
    let discordLink;
    const currentUrl = window.location.hostname

    switch (currentUrl) {
      case 'www.fibergamingnetwork.com':
        discordLink = 'https://discord.com/api/oauth2/authorize?client_id=1095054196732067841&redirect_uri=https%3A%2F%2Fwww.fibergamingnetwork.com%2Fdiscord&response_type=token&scope=identify%20guilds.join'
        break;
      case 'beta.fibergamingnetwork.com':
        discordLink = 'https://discord.com/api/oauth2/authorize?client_id=1095054196732067841&redirect_uri=https%3A%2F%2Fbeta.fibergamingnetwork.com%2Fdiscord&response_type=token&scope=identify%20guilds.join'
        break;
      case 'fibergamingnetwork.com':
        discordLink = 'https://discord.com/api/oauth2/authorize?client_id=1095054196732067841&redirect_uri=https%3A%2F%2Ffibergamingnetwork.com%2Fdiscord&response_type=token&scope=identify%20guilds.join'
        break;
      case 'localhost':
        discordLink = 'https://discord.com/api/oauth2/authorize?client_id=1095054196732067841&redirect_uri=http%3A%2F%2Flocalhost%3A3000%2Fdiscord&response_type=token&scope=identify%20guilds.join'
        break;
    }

    return discordLink
  }

  const getFormLink = () => {
    let formLink;
    const currentUrl = window.location.hostname

    switch (currentUrl) {
      case 'www.fibergamingnetwork.com':
        formLink = 'www'
        break;
      case 'beta.fibergamingnetwork.com':
        formLink = 'beta'
        break;
      case 'fibergamingnetwork.com':
        formLink = 'none'
        break;
      case 'localhost':
        formLink = 'localhost'
        break;
    }

    return formLink
  }

  useEffect(() => {
    if (queryParams.has('username')) {
      setUsername(queryParams.get('username'))
    }
    if (queryParams.has('provider')) {
      setUserVerified('signupProvider');
    }
    if (queryParams.has('inviteCode')) {
      setUserVerified('signupSchool');
    }
    listProviders();
    if (userVerified === 'profileInfo') {
      Auth.currentAuthenticatedUser().then(currentUser => {
        setUsername(currentUser)
      });
      Auth.currentUserInfo().then((user) => {
        console.log(user);
        setAddress(user.attributes.address);
        if (user.attributes['custom:provider'] !== 'noProvider') {
          API.graphql({
            query: customqueries.getProviderProfile,
            variables: { id: user.attributes['custom:provider'] },
            authMode: GRAPHQL_AUTH_MODE.API_KEY
          }).then((response) => {
            setProvider(response.data.getProviderProfile);
            setProviderLogo(response.data.getProviderProfile.images);
            setProviderName(response.data.getProviderProfile.name);
          }).catch(err => console.log(err));
        } else {
          setProvider('noProvider');
        }
      })
    }
    console.log(userVerified);
  }, []);

  useEffect(() => {
    if (userVerified === 'complete') {
      // Get the current user attributes
      Auth.currentUserInfo().then((user) => {
        // If they used an invite code, continue
        // if (user.attributes['custom:invite_code']) {
        //   setUserInviteCode(user.attributes['custom:invite_code']);
        //   setTypeFormUserInfo(user);

        //   console.log(user.attributes);

        //   // Check that the user has a provider
        //   if (user.attributes['custom:provider'] !== 'noProvider') {
        //     API.graphql({
        //       query: customqueries.getProviderProfile,
        //       variables: { id: user.attributes['custom:provider'] },
        //       authMode: GRAPHQL_AUTH_MODE.API_KEY
        //     }).then((response) => {
        //       //Set the provider details
        //       setProvider(response.data.getProviderProfile);
        //       setProviderLogo(response.data.getProviderProfile.images);
        //       setProviderName(response.data.getProviderProfile.name);
        //       //Get their user profile
        //       API.graphql({
        //         query: queries.getUserProfile,
        //         variables: { id: user.attributes.sub },
        //         authMode: GRAPHQL_AUTH_MODE.API_KEY
        //       }).then((response) => {
        //         console.log(response);
        //         // Check to see that their discord is set
        //         if (user.attributes['custom:discord_username']) {
        //           if (user.attributes['custom:status'] && user.attributes['custom:status'] === 'registeredForPreview') {
        //             setTypeFormProfileInfo('registeredForPreview')
        //             setTypeFormButtonLoading(false)
        //           } else {
        //             if (queryParams.has('registrationid') && (queryParams.get('registrationid') === 'baec2766f0da4b8bab275661ee1bb4b1')) {
        //               Auth.currentAuthenticatedUser().then((user) => {
        //                 Auth.updateUserAttributes(user, {
        //                   'custom:status': 'registeredForPreview'
        //                 }).then(() => {
        //                   setTypeFormProfileInfo('registeredForPreview')
        //                   setTypeFormButtonLoading(false)
        //                 }).catch(err => console.log(err));
        //               }).catch(err => console.log(err));
        //             } else {
        //               setTypeFormProfileInfo(response.data.getUserProfile)
        //               setTypeFormButtonLoading(false)
        //             }
        //           }

        //         } else {
        //           // If discord is not set, say we're viewing only.
        //           setTypeFormProfileInfo('viewingOnly')
        //           setTypeFormButtonLoading(false)
        //         }
        //       }).catch(err => console.log(err));
        //     }).catch(err => console.log(err));
        //   } else {
        //     setProvider('noProvider');
        //   }




        // }
      }).catch(err => console.log(err));
    }
  }, [userVerified]);

  useEffect(() => {
    if (!username && provider && provider !== '' && (userVerified !== 'profileInfo') && (provider !== 'noProvider') & userVerified !== 'complete') {
      setUserVerified('providerConfirmed');
    }

    if (!username && provider === 'noProvider') {
      if (userVerified !== 'complete') {
        setUserVerified('userInfo')
      }
    }
  }, [provider]);

  useEffect(() => {
    console.log(provider);
  }, [provider])

  const StyledImg = styled('img')`
    width: 100%,
    height: auto
  `;
  return (
    <>
      <ProviderImages providerLogo={((provider.images && provider.images !== '') || providerLogo !== '') ? providerLogo : null} />
      <Card elevation={3} sx={{ marginBottom: 5, width: '100%', padding: { xs: 2.8, md: 5 }, borderRadius: 5, backgroundColor: 'rgba(0, 0, 0, 0.64)' }}>
        {(userVerified === 'none') &&
          <Grid container spacing={5} justifyContent={'center'}>
            <Grid item xs={12}>
              <Typography variant='h4' textAlign={'center'}>
                Get in the game.
              </Typography>
              <Typography variant='body1' textAlign={'center'} fontSize={20} mt={3}>
                Sign up through a participating internet provider for <b>free access</b> to events.
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12}>
              <Button sx={{ paddingY: 3, fontSize: { xs: 13, md: 17 }, borderRadius: 8 }} onClick={() => { setUserVerified('signupProvider') }} fullWidth variant='contained'>
                Sign Up With Internet Provider
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Divider>
                OR
              </Divider>
            </Grid>
            <Grid item xs={12} sm={12}>
              <Button sx={{ paddingY: 3, fontSize: { xs: 13, md: 17 }, borderRadius: 8 }} onClick={() => { setUserVerified('signupSchool') }} fullWidth variant='contained'>
                Sign Up With Code
              </Button>
            </Grid>
            {/* <Grid item xs={12}>
              <Divider>
                OR
              </Divider>
            </Grid> */}
            {/* <Grid item xs={12} sm={12}>
              <Button
                sx={{
                  paddingY: 1.5,
                  fontSize: { xs: 13, md: 17 },
                  borderRadius: 5
                }}
                onClick={() => {
                  setUserVerified('userInfo');
                  setProvider('noProvider');
                }}
                color='neutral'
                fullWidth
                variant='contained'
                disableElevation
              >
                Sign Up With Email
              </Button>
            </Grid> */}
            <Grid item xs={12}>
              <Typography variant='body1' textAlign={'center'}>
                <Link variant='body1' sx={{ cursor: 'pointer' }} onClick={() => {
                  navigate(`/login`);
                }}>
                  Already have an account? Click here to log in.
                </Link>
              </Typography>
            </Grid>
          </Grid>
        }
        {(userVerified === 'signupProvider') &&
          <SelectProvider
            setPartnerAccountNumber={setPartnerAccountNumber}
            setAddressSetWithProvider={setAddressSetWithProvider}
            setProviderLogo={setProviderLogo}
            showSnackbar={showSnackbar}
            setShowSnackbar={setShowSnackbar}
            address={address}
            setAddress={setAddress}
            provider={provider}
            setProvider={setProvider}
            handleSelectProvider={handleSelectProvider}
            setFormErrors={setFormErrors}
            formErrors={formErrors} providers={providers}
            loadingProviders={loadingProviders}
            setUserVerified={setUserVerified}
          />
        }
        {(userVerified === 'signupSchool') &&
          <SelectSchool
            setAddressSetWithProvider={setAddressSetWithProvider}
            setProviderLogo={setProviderLogo}
            showSnackbar={showSnackbar}
            setShowSnackbar={setShowSnackbar}
            address={address}
            setAddress={setAddress}
            provider={provider}
            setProvider={setProvider}
            handleSelectProvider={handleSelectProvider}
            setFormErrors={setFormErrors}
            formErrors={formErrors} providers={providers}
            loadingProviders={loadingProviders}
            inviteCode={userInviteCode}
            setInviteCode={setUserInviteCode}
            setUserVerified={setUserVerified}
          />
        }
        {(userVerified === 'providerConfirmed') &&
          <>
            <Typography component='h4' variant='h4' textAlign='center' mb={2}>
              Provider Confirmed
            </Typography>
            <Stack direction="row" alignItems="center" justifyContent={'center'} gap={1} marginBottom={5}>
              <Check fontSize='large' color="success" />
              <Typography variant='h5' textAlign={'center'}>
                {providerName}
              </Typography>
            </Stack>
            <center>

            </center>
            <Divider />

            <Typography variant='h5' marginTop={5} textAlign='center' marginBottom={0}>
              Your local internet provider is covering your costs and providing some awesome perks!
            </Typography>
            <Grid container justifyContent='left' spacing={2} mb={5} mt={4}>
              <Grid item xs={12}>
                <Stack direction="row" alignItems="center" justifyContent={'left'} gap={1}>
                  <SportsEsports />
                  <Typography variant='body1' textAlign='left' fontSize={'1.3em'}>
                    Free Event Registrations
                  </Typography>
                </Stack>
              </Grid>
              <Grid item xs={12}>
                <Stack direction="row" alignItems="center" justifyContent={'left'} gap={1}>
                  <SportsEsports />
                  <Typography variant='body1' textAlign='left' fontSize={'1.3em'}>
                    Weekly Tournaments
                  </Typography>
                </Stack>
              </Grid>
              <Grid item xs={12}>
                <Stack direction="row" alignItems="center" justifyContent={'left'} gap={1}>
                  <SportsEsports />
                  <Typography variant='body1' textAlign='left' fontSize={'1.3em'}>
                    Giveaway Nights
                  </Typography>
                </Stack>
              </Grid>
              <Grid item xs={12}>
                <Stack direction="row" alignItems="center" justifyContent={'left'} gap={1}>
                  <SportsEsports />
                  <Typography variant='body1' textAlign='left' fontSize={'1.3em'}>
                    And More!
                  </Typography>
                </Stack>
              </Grid>
            </Grid>

            <Button fullWidth size="small" variant='contained' sx={{ marginTop: 1.3, paddingY: 1.5, fontSize: 17 }} onClick={() => { setUserVerified('userInfo') }}>
              Next
            </Button>
          </>
        }
        {(userVerified === 'userInfo') &&
          <UserInformationForm termsOfServiceError={termsOfServiceError} setTermsOfServiceError={setTermsOfServiceError} inviteCode={inviteCode} usernameExist={usernameExist} termsOfService={termsOfService} privacyPolicy={privacyPolicy} setPrivacyPolicy={setPrivacyPolicy} setTermsOfService={setTermsOfService} addressSetWithProvider={addressSetWithProvider} signUpFormVerificationErrors={signUpFormVerificationErrors} setSignUpFormVerificationErrors={setSignUpFormVerificationErrors} providerConfirmed={providerConfirmed} signupOnly={signupOnly} providerName={providerName} address={address} setAddress={setAddress} username={username} setUsername={setUsername} handleNextStep={handleNextStep} email={email} setEmail={setEmail} password={password} setPassword={setPassword} showPassword={showPassword} setShowPassword={setShowPassword} verifiedPassword={verifiedPassword} setVerifiedPassword={setVerifiedPassword} formErrors={formErrors} setFormErrors={setFormErrors} loading={loading} />
        }
        {userVerified === 'loginToVerify' &&
          <LogInToVerifyForm setUserVerified={setUserVerified} setShowSnackbar={setShowSnackbar} verificationError={verificationError} profileData={profileData} username={username} showPassword={showPassword} password={password} setPassword={setPassword} formErrors={formErrors} setFormErrors={setFormErrors} handleVerification={handleVerification} setShowPassword={setShowPassword} loading={loading} />
        }
        {userVerified === 'discord' &&
          <>
            <Typography variant='h4' textAlign={'center'} mt={0}>
              Connect Discord
            </Typography>
            <Typography variant='body1' textAlign={'center'} lineHeight={1.9} mb={5} mt={3}>
              In order to play in our events, you’ll need to connect your Discord account. If you just want to watch the livestreams, you can skip this step.
            </Typography>
            <Grid container justifyContent={'center'} spacing={5}>
              <Grid item xs={12}>
                <Button sx={{ paddingY: 1.5, fontSize: 17 }} variant='contained' href={getDiscordLoginLink()} fullWidth>
                  <FontAwesomeIcon icon={faDiscord} style={{ marginRight: 8 }} /> Connect Discord
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Typography variant='body2' textAlign={'center'}>
                  <Link variant='body2' sx={{ cursor: 'pointer' }} onClick={() => { handleClickOpen() }}>
                    Skip for now
                  </Link>
                </Typography>
              </Grid>
            </Grid>
            <Dialog
              open={discordConfirm}
              onClose={handleClickClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                {"Are You Sure?"}
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  Discord is required to play in events. Without Discord, you will only be able to watch events. Are you sure you want to continue without Discord?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button sx={{ marginX: 1, marginY: 1, width: { xs: '100%', md: 'auto' }, textAlign: 'center' }} variant='contained' href={getDiscordLoginLink()}>Connect Discord</Button>
                <Button sx={{ marginX: 1, marginY: 1, width: { xs: '100%', md: 'auto' }, textAlign: 'center' }} variant='contained' color='error' onClick={() => { setUserVerified('profileInfo') }}>
                  I Don't Want To Play
                </Button>
              </DialogActions>
            </Dialog>
          </>
        }
        {userVerified === 'profileInfo' &&
          <>
            {!signupOnly &&
              <>
                <Typography variant="h4" textAlign={signupOnly ? 'center' : 'left'} mb={0}>
                  More Information
                </Typography>
                <Divider sx={{ my: 6 }} />
              </>
            }
            {signupOnly &&
              <>
                <Typography variant='h4' textAlign={'center'} mt={0}>
                  One Last Step!
                </Typography>
                <Typography variant='body1' textAlign={'center'} mt={3} mb={3}>
                  We just need a few more details to finish creating your <b>Fiber&nbsp;Gaming&nbsp;Network</b> account
                </Typography>
                <Divider sx={{ mb: 3, mt: 0 }}>
                  <Typography variant='h6'>
                    Profile Details
                  </Typography>
                </Divider>
              </>
            }
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  variant="outlined"
                  label="First Name"
                  autoCapitalize
                  disabled={disableInputs}
                  error={formErrors.firstName || profileInfoVerificationErrors.firstName}
                  helperText={profileInfoVerificationErrors.firstName ? 'Names may be up to 50 characters and contain letters and hyphens.' : null}
                  onKeyDown={(event) => {
                    if (event.key === 'Enter') {
                      handleSignUp();
                    }
                  }}
                  value={firstName}
                  onChange={(event) => {
                    setFormErrors({ ...formErrors, firstName: false });
                    setProfileInfoVerificationErrors({
                      ...profileInfoVerificationErrors,
                      firstName: false
                    })
                    setFirstName(event.target.value)
                  }} />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  variant="outlined"
                  label="Last Name"
                  autoCapitalize
                  disabled={disableInputs}
                  error={formErrors.lastName || profileInfoVerificationErrors.lastName}
                  helperText={profileInfoVerificationErrors.lastName ? 'Names may be up to 50 characters and contain letters and hyphens.' : null}
                  onKeyDown={(event) => {
                    if (event.key === 'Enter') {
                      handleSignUp();
                    }
                  }}
                  value={lastName}
                  onChange={(event) => {
                    setFormErrors({ ...formErrors, lastName: false });
                    setProfileInfoVerificationErrors({
                      ...profileInfoVerificationErrors,
                      lastName: false
                    })
                    setLastName(event.target.value)
                  }} />
              </Grid>
              <Grid item xs={12}>
                {/* <DatePicker
                  sx={{ width: '100%' }}
                  value={(birthdate !== '') ? addDays(birthdate) : null}
                  label="Birthday"
                  disabled={disableInputs}
                  onKeyDown={(event) => {
                    if (event.key === 'Enter') {
                      handleSignUp();
                    }
                  }}
                  onChange={(value) => {
                    setFormErrors({ ...formErrors, birthdate: false });
                    setBirthdate(value);
                  }}
                  slotProps={{ textField: { error: formErrors.birthdate } }}

                /> */}

                <DatePicker
                  openTo="year"
                  views={['year', 'month', 'day']}
                  label="Birthdate"
                  value={birthdate}
                  disabled={disableInputs}
                  onChange={(value) => {
                    setFormErrors({ ...formErrors, birthdate: false });
                    setBirthdate(value);
                  }}
                  renderInput={(params) => (
                    <TextField {...params} fullWidth helperText={null} />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <LoadingButton sx={{ paddingY: 1.5, fontSize: 17 }} loading={loading} onClick={handleSignUp} variant='contained' fullWidth size='large'>
                  Finish Sign up
                </LoadingButton>
              </Grid>
            </Grid>
          </>
        }
        {(userVerified === 'verify') &&
          <>

            <Stack direction="row" alignItems="center" justifyContent={'center'} gap={1} marginBottom={3}>
              <Mail fontSize='large' color="success" />
              <Typography component='h5' variant='h5' textAlign='center'>
                Please Verify Your Account
              </Typography>
            </Stack>
            <Divider />
            <Typography component='p' variant='body' marginY={3} textAlign='center' fontSize={'1.3em'}>
              We just emailed you a link to verify your account. (It may be in your Spam folder.) Please open the email and click the link to proceed.
            </Typography>
            <LoadingButton loading={loading} fullWidth size="small" variant='text' sx={{ marginTop: 3 }} onClick={handleSendVerification}>
              Didn't recieve the email? Click here to resend.
            </LoadingButton>
            {/* <Typography variant='body1' textAlign='center' marginTop={3}>
            <Link variant="subtitle2" underline='hover' sx={{ cursor: 'pointer' }} onClick={() => setUserVerified('verifyCode')}>
              Were you sent a code? Click here.
            </Link>
          </Typography> */}
          </>
        }
        {(userVerified === 'verifyCode') &&
          <>
            <Typography component='h3' variant='h3' marginBottom={3} textAlign='center'>
              Verification Email Sent
            </Typography>
            <Divider />
            <Typography component='p' variant='body' marginY={3} textAlign='center' fontSize={'1.3em'}>
              We've sent an email with a verification code. Please enter it below.
            </Typography>
            <TextField
              disabled={disableInputs}
              name='verification-code'
              value={verificationCode}
              onChange={(event) => setVerificationCode(event.target.value)}
              type='number'
              color={verificationError}
              onKeyDown={(event) => {
                if (event.key === 'Enter') {
                  handleVerificationCode();
                }
              }}
              inputProps={{
                style: {
                  fontSize: '3rem',
                  fontWeight: '700',
                  textAlign: 'center'
                },
                typeof: 'number'
              }}
            />
            <Stack direction='row' spacing={3} marginTop={3}>
              <LoadingButton disabled={disableInputs} loading={loading} size="large" fullWidth type="submit" variant="contained" color='primary' onClick={handleSendVerification}>
                Resend Code
              </LoadingButton>
              <LoadingButton disabled={disableInputs} loading={verificationCodeLoading} size="large" fullWidth type="submit" variant="contained" onClick={handleVerificationCode}>
                {verificationButtonText}
              </LoadingButton>
            </Stack>
          </>
        }
        {(userVerified === 'complete') &&
          /* TODO: We need to at least throw an invite link to the Discord server here. I need to look back through their docs, but I think we may have to
             build the bot to retrieve this information. I don't know if just hitting an end point will work, but I will have to do some more digging. */
          <Grid container justifyContent={'center'} spacing={3}>
            <Grid item xs={4} sx={{ mt: { xs: 3, md: 0 } }} sm={3}>
              <StyledImg src='assets/trophy_icon.svg' alt='trophy' />
            </Grid>
            <Grid item xs={12}>
              <Typography variant='h4' textAlign={'center'}>
                You're in!
              </Typography>
              {/* {userInviteCode &&
                <>
                  <Typography variant='h6' paddingTop={2} mb={3} textAlign={'center'}>

                    {(typeFormProfileInfo && providerName && typeFormProfileInfo === 'viewingOnly') ? `We've added you to the Fiber Gaming Network Preview event. If you're playing in any of the tournaments on April 29, please register below.` : (typeFormProfileInfo && typeFormProfileInfo === 'registeredForPreview') ? `We've added you to the Fiber Gaming Network Preview event. If you're playing in any of the tournaments on April 29, please join our Discord below.` : `We've added you to the Fiber Gaming Network Preview event. If you're playing in any of the tournaments on April 29, please register below.`}
                  </Typography>
                  <LoadingButton
                    loading={typeFormButtonLoading}
                    sx={{ paddingY: 1.5, fontSize: 17 }}
                    fullWidth
                    variant='contained'
                    disabled={(typeFormProfileInfo && providerName && (typeFormProfileInfo === 'viewingOnly'))}
                    href={(typeFormProfileInfo && providerName && typeFormProfileInfo !== 'viewingOnly' && typeFormProfileInfo !== 'registeredForPreview') ? `https://mainstage.typeform.com/employeeevent#name=${encodeURIComponent(`${typeFormUserInfo.attributes.given_name} ${typeFormUserInfo.attributes.family_name}`)}&email=${encodeURIComponent(typeFormUserInfo.attributes.email)}&isp=${encodeURIComponent(providerName)}&discord_username=${encodeURIComponent(typeFormProfileInfo.discordUsername)}&fgn_username=${encodeURIComponent(typeFormUserInfo.username)}&subdomain=${getFormLink()}` : 'https://discord.gg/EewAd5mkGY'}
                  >
                    {(typeFormProfileInfo && providerName && typeFormProfileInfo === 'viewingOnly') ? 'Account created as view only' : (typeFormProfileInfo && typeFormProfileInfo === 'registeredForPreview') ? 'Join Discord' : 'Register For Tournaments'}

                  </LoadingButton>
                  <Typography variant='body1' lineHeight={2} mt={3} textAlign={'center'}>
                    If you're planning to livestream the action, bookmark or follow any of the below social links so you can log in on April 29 and watch.
                  </Typography>
                </>
              } */}
              {true &&
                <>
                  <Typography variant='body1' lineHeight={2} textAlign={'center'} my={3}>
                    You'll need to join our Discord to play in tournaments.<br />Would you like to join now?
                  </Typography>
                  <LoadingButton
                    sx={{ paddingY: 1.5, fontSize: 17 }}
                    fullWidth
                    variant='contained'
                    href={getDiscordLoginLink()}
                  >
                    Join Discord
                  </LoadingButton>
                  <Divider sx={{ my: 3 }}>
                    OR
                  </Divider>
                  <Typography variant='body1' textAlign='center'>
                    <Link sx={{ cursor: 'pointer' }} onClick={() => {
                      navigate('/dashboard');
                    }}>
                      Join Discord Later
                    </Link>
                  </Typography>
                </>
              }
            </Grid>
            <Grid item xs={12}>
              {/* <Button sx={{ paddingY: 1.5, fontSize: 17 }} fullWidth variant='contained'>
                Invite a friend!
              </Button> */}

              <Stack direction="row" width='100%' justifyContent='space-around' alignItems="center" spacing={1}>
                <IconButton size='large' href='https://www.youtube.com/channel/UClRFypeJY-fbj-7mlKs1GdQ' target='_blank'>
                  <Box sx={{ aspectRatio: '1/1' }}>
                    <FontAwesomeIcon style={{ fontSize: 50, padding: 10, paddingTop: 13 }} icon={faYoutube} />
                  </Box>
                </IconButton>
                <IconButton size='large' href='https://www.twitch.tv/fibergaming_' target='_blank'>
                  <Box sx={{ aspectRatio: '1/1' }}>
                    <FontAwesomeIcon style={{ fontSize: 40, padding: 15, paddingTop: 18 }} icon={faTwitch} />
                  </Box>
                </IconButton>
                <IconButton size='large' href='https://www.facebook.com/fibergamingnetwork' target='_blank'>
                  <Box sx={{ aspectRatio: '1/1' }}>
                    <FontAwesomeIcon style={{ fontSize: 40, padding: 15 }} icon={faFacebook} />
                  </Box>
                </IconButton>
              </Stack>
            </Grid>
          </Grid>
        }
      </Card>
      {showSnackbar.status ? <SnackbarAlert show={showSnackbar} setShow={setShowSnackbar} message={showSnackbar.message} type={showSnackbar.type} /> : null}
    </>
  );
}
