import { useState } from 'react';
// @mui
import { MenuItem, Stack } from '@mui/material';
// locales
import { useLocales } from '../../../locales';
// components
import Image from '../../../components/image';
import MenuPopover from '../../../components/menu-popover';
import { IconButtonAnimate } from '../../../components/animate';
import ModeOptions from 'src/components/settings/drawer/ModeOptions';
import SvgColor from 'src/components/svg-color/SvgColor';
import { useSettingsContext } from 'src/components/settings';

// ----------------------------------------------------------------------

export default function ThemeColorPicker() {
  const { allLangs, currentLang, onChangeLang } = useLocales();
  const { themeMode, onChangeMode } = useSettingsContext();

  const [openPopover, setOpenPopover] = useState(null);

  const handleOpenPopover = (event) => {
    setOpenPopover(event.currentTarget);
  };

  const handleClosePopover = () => {
    setOpenPopover(null);
  };

  const handleChangeLang = (newLang) => {
    onChangeLang(newLang);
    handleClosePopover();
  };

  return (
    <>
      <IconButtonAnimate
        onClick={handleOpenPopover}
        sx={{
          width: 40,
          height: 40,
          ...(openPopover && {
            bgcolor: 'action.selected',
          }),
        }}
      >
        {/* <Image disabledEffect src={currentLang.icon} alt={currentLang.label} /> */}
        <SvgColor
              src={`/assets/icons/setting/${themeMode === 'light' ? 'ic_sun' : 'ic_moon'}.svg`}
            />
      </IconButtonAnimate>

      <MenuPopover open={openPopover} onClose={handleClosePopover} sx={{ width: 180 }}>
        {/* <Stack spacing={0.75}>
          {allLangs.map((option) => (
            <MenuItem
              key={option.value}
              selected={option.value === currentLang.value}
              onClick={() => handleChangeLang(option.value)}
            >
              <Image
                disabledEffect
                alt={option.label}
                src={option.icon}
                sx={{ width: 28, mr: 2 }}
              />

              {option.label}
            </MenuItem>
          ))}
        </Stack> */}
        <ModeOptions handleClosePopover={handleClosePopover} />
      </MenuPopover>
    </>
  );
}
