import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { AppBar, Toolbar, Box, Link, Typography, Dialog } from '@mui/material';
// config
import { HEADER } from '../../config-global';
// utils
import { bgBlur } from '../../utils/cssStyles';
// routes
import { PATH_PAGE } from '../../routes/paths';
// components
import Logo from '../../components/logo';
import { useState } from 'react';
import AccountPopover from '../dashboard/header/AccountPopover';

// ----------------------------------------------------------------------

Header.propTypes = {
  isOffset: PropTypes.bool,
};

export default function Header({ isOffset, userProfile }) {
  const theme = useTheme();
  const [open, setOpen] = useState(false);

  const toggleOpen = () => {
    setOpen(!open)
  }

  return (
    <AppBar color="transparent" sx={{ boxShadow: 0 }}>
      <Toolbar
        sx={{
          justifyContent: 'space-between',
          height: {
            xs: HEADER.H_MOBILE,
            md: HEADER.H_MAIN_DESKTOP,
          },
          transition: theme.transitions.create(['height', 'background-color'], {
            easing: theme.transitions.easing.easeInOut,
            duration: theme.transitions.duration.shorter,
          }),
          ...(isOffset && {
            ...bgBlur({ color: theme.palette.background.default }),
            height: {
              md: HEADER.H_MAIN_DESKTOP - 16,
            },
          }),
        }}
      >
        <Logo whiteLogo />

        {!userProfile &&
          <Link variant="subtitle2" sx={{ cursor: 'pointer', color: '#FFFFFF' }} onClick={toggleOpen} color="inherit">
            Need Help?
          </Link>
        }
        {userProfile &&
          <AccountPopover />
        }
      </Toolbar>
      <Dialog
        open={open}
        onClose={toggleOpen}
        fullWidth
        maxWidth='sm'
      >
        <Typography variant='h4'
          sx={{ paddingX: 5, pt: 5, pb: 3 }} textAlign={'center'}>
          Need help?
        </Typography>
        <Typography variant='body1'
          sx={{ paddingX: 5, pb: 5 }} textAlign={'center'}>
          While we're still in beta, please email us at <Link href="mailto:support@fibergamingnetwork.com">support@fibergamingnetwork.com</Link>
        </Typography>
      </Dialog>

      {isOffset && <Shadow />}
    </AppBar>
  );
}

// ----------------------------------------------------------------------

Shadow.propTypes = {
  sx: PropTypes.object,
};

function Shadow({ sx, ...other }) {
  return (
    <Box
      sx={{
        left: 0,
        right: 0,
        bottom: 0,
        height: 24,
        zIndex: -1,
        m: 'auto',
        borderRadius: '50%',
        position: 'absolute',
        width: `calc(100% - 48px)`,
        boxShadow: (theme) => theme.customShadows.z8,
        ...sx,
      }}
      {...other}
    />
  );
}
