import ReactDOM from 'react-dom/client';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import { Amplify } from 'aws-amplify';
import awsConfig from './aws-exports';

const root = ReactDOM.createRoot(document.getElementById('root'));

const DiscordFragment = new URLSearchParams(window.location.hash.slice(1));
const [accessToken, tokenType, scope] = [DiscordFragment.get('access_token'), DiscordFragment.get('token_type'), DiscordFragment.get('scope')];

if (accessToken && tokenType && scope === 'identify guilds.join')  {
    console.log(scope)
    window.sessionStorage.setItem('discordAccessToken', accessToken)
    window.sessionStorage.setItem('discordTokenType', tokenType)
    window.history.replaceState(null, null, ' ');
}

// Get the base URL of the current page
const url = window.location.origin;

// Modify the redirectSignIn property for your OAuth configuration
awsConfig.oauth.redirectSignIn = `${url}/oauthlogin/`;
awsConfig.oauth.redirectSignOut = `${url}/login/`;

// Call Amplify.configure() with your updated configuration
console.log(awsConfig)
Amplify.configure(awsConfig);

root.render(<App />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
