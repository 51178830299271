import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import parse from 'autosuggest-highlight/parse';
import { debounce } from '@mui/material/utils';
import { useEffect, useMemo, useRef, useState } from 'react';

// This key was created specifically for the demo in mui.com.
// You need to create a new one for your application.
const GOOGLE_MAPS_API_KEY = 'AIzaSyD-XG_acNaYWzzLMANrmcwLmjxrAbRzwcQ';

function loadScript(src, position, id) {
    if (!position) {
        return;
    }

    const script = document.createElement('script');
    script.setAttribute('async', '');
    script.setAttribute('id', id);
    script.src = src;
    position.appendChild(script);
}

const autocompleteService = { current: null };

export default function GoogleAutoComplete({ address, setAddress, formErrors, setFormErrors }) {
    const [value, setValue] = useState(null);
    const [inputValue, setInputValue] = useState('');
    const [options, setOptions] = useState([]);
    const loaded = useRef(false);

    if (typeof window !== 'undefined' && !loaded.current) {
        if (!document.querySelector('#google-maps')) {
            loadScript(
                `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&libraries=places`,
                document.querySelector('head'),
                'google-maps',
            );
        }

        loaded.current = true;
    }

    const fetch = useMemo(
        () =>
            debounce((request, callback) => {
                autocompleteService.current.getPlacePredictions(request, callback);
            }, 400),
        [],
    );

    function getPostalCode(components) {
        for (let i = 0; i < components.length; i++) {
            const component = components[i];
            if (component.types.includes("postal_code")) {
                return component.long_name;
            }
        }
        return "";
    }

    const handleSelect = async (address) => {
        setValue(address);
        setInputValue(address.description);
        const placeDetails = await new Promise((resolve) => {
            const service = new window.google.maps.places.PlacesService(
                document.createElement("div")
            );
            service.getDetails({ placeId: address.place_id }, (place) => {
                resolve(place);
            });
        });
        const addressComponents = placeDetails.address_components;
        const streetNumber = addressComponents.find(component => component.types.includes('street_number'))?.long_name;
        const route = addressComponents.find(component => component.types.includes('route'))?.long_name;
        const city = addressComponents.find(component => component.types.includes('locality'))?.long_name;
        const state = addressComponents.find(component => component.types.includes('administrative_area_level_1'))?.short_name;
        const country = addressComponents.find(component => component.types.includes('country'))?.long_name;
        const zipCode = addressComponents.find(component => component.types.includes('postal_code'))?.long_name;
        console.log(placeDetails)

        setAddress({
            fullAddress: `${streetNumber} ${route}, ${city}, ${state}, ${country}`,
            street: `${streetNumber} ${route}`,
            city,
            state,
            country,
            zipCode,
        });
    };


    useEffect(() => {
        let active = true;

        if (!autocompleteService.current && window.google) {
            autocompleteService.current =
                new window.google.maps.places.AutocompleteService();
        }
        if (!autocompleteService.current) {
            return undefined;
        }

        const options = {
            input: inputValue,
            componentRestrictions: { country: ["us", "ca"] }, // Set the country as needed
            types: ["address"], // Limit results to addresses only
        };

        if (inputValue === '') {
            setOptions(value ? [value] : []);
            return undefined;
        }

        fetch(options, (results) => {
            console.log(results)
            if (active) {
                let newOptions = [];

                if (value) {
                    newOptions = [value];
                }

                if (results) {
                    console.log(results)
                    const filteredResults = results.filter(resultObj => {
                        return !resultObj.types.includes('route');
                    });
                    
                    newOptions = [...newOptions, ...filteredResults];
                    console.log(newOptions)
                }

                setOptions(newOptions);
            }
        });

        return () => {
            active = false;
        };
    }, [value, inputValue, fetch]);


    return (
        <Autocomplete
            id="google-map-search"
            fullWidth
            getOptionLabel={(option) =>
                typeof option === 'string' ? option : option.description
            }
            filterOptions={(x) => x}
            options={options}
            autoComplete
            includeInputInList
            filterSelectedOptions
            value={value}
            noOptionsText={inputValue ? 'Loading results...' : 'Waiting for address...'}
            onChange={(event, newValue) => {
                setOptions(newValue ? [newValue, ...options] : options);
                setValue(newValue);
                handleSelect(newValue)
            }}
            onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
            }}
            renderInput={(params) => (
                <TextField {...params} error={formErrors.address} onChange={() => setFormErrors({...formErrors, address: false})} label="Home Address" fullWidth />
            )}
            renderOption={(props, option) => {
                const matches =
                    option.structured_formatting.main_text_matched_substrings || [];

                const parts = parse(
                    option.structured_formatting.main_text,
                    matches.map((match) => [match.offset, match.offset + match.length]),
                );

                return (
                    <li {...props}>
                        <Grid container alignItems="center">
                            <Grid item sx={{ display: 'flex', width: 44 }}>
                                <LocationOnIcon sx={{ color: 'text.secondary' }} />
                            </Grid>
                            <Grid item sx={{ width: 'calc(100% - 44px)', wordWrap: 'break-word' }}>
                                {parts.map((part, index) => (
                                    <Box
                                        key={index}
                                        component="span"
                                        sx={{ fontWeight: part.highlight ? 'bold' : 'regular' }}
                                    >
                                        {part.text}
                                    </Box>
                                ))}

                                <Typography variant="body2" color="text.secondary">
                                    {option.structured_formatting.secondary_text}
                                </Typography>
                            </Grid>
                        </Grid>
                    </li>
                );
            }}
        />
    );
}