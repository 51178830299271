import { Card, Container, CssBaseline, Divider, Grid, ThemeProvider, Typography, createTheme, styled } from '@mui/material';
import './PublicAuth.css'
import { LoginForm } from '../../sections/auth/login2';
import { Helmet } from 'react-helmet-async';
import { useEffect } from 'react';


const theme = createTheme({
    palette: {
        mode: 'dark',
        background: {
            default: '#264162'
        },
        primary: {
            main: '#98C03C',
        },
        secondary: {
            main: '#1162ae',
        },
        neutral: {
            main: '#404040',
            contrastText: '#fff',
        },
    }
})

const BackgroundGrid = styled(Grid)({
});

const Logo = styled('img')({
    width: '100%',
    height: 'auto'
});



export default function Login() {

    useEffect(() => {
        const documentBody = document.body;
        documentBody.classList.add('auth-bg');

        return function removeBackground() {
            const documentBody = document.body;
            documentBody.classList.remove('auth-bg');
        }
    }, []);

    return (
        <>
            <Helmet>
                <title>Login | Fiber Gaming Network</title>
            </Helmet>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <BackgroundGrid container justifyContent='center' paddingBottom={6} sx={{ pt: { xs: 2, md: 5 } }} minHeight='100vh'>
                    <Container maxWidth='sm'>
                        <Grid container justifyContent='center' alignContent='center'>
                            <Grid item xs={12}>
                                <Grid container sx={{ mb: { xs: 20, md: 20 } }} justifyContent={'center'}>
                                    <Grid item xs={12} md={8}>
                                    </Grid>
                                </Grid>
                                <Card elevation={3} sx={{ marginBottom: 5, width: '100%', padding: { xs: 2.8, md: 5 }, borderRadius: 5, backgroundColor: 'rgba(0, 0, 0, 0.64)' }}>
                                    <Typography variant='h4' textAlign={'center'}>
                                        Welcome Back!
                                    </Typography>
                                    <Divider sx={{ my: 5 }}>
                                        <Typography variant='h6'>
                                            Login
                                        </Typography>
                                    </Divider>
                                    <LoginForm />
                                </Card>
                            </Grid>
                        </Grid>
                    </Container>
                </BackgroundGrid>
            </ThemeProvider>
        </>
    )
}