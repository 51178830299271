import { Navigate, Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
// @mui
import { styled, alpha } from '@mui/material/styles';
import { Box, Button, Link, Typography } from '@mui/material';
// auth
import { useAuthContext } from '../../../auth/useAuthContext';
// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// components
import { CustomAvatar } from '../../../components/custom-avatar';
import { Stack } from '@mui/system';
import { useEffect } from 'react';

// ----------------------------------------------------------------------

const StyledRoot = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: alpha(theme.palette.grey[500], 0.12),
}));

// ----------------------------------------------------------------------

export default function NavAccount() {
  const { user } = useAuthContext();
  const navigate = useNavigate()
  const { pathname } = useLocation();

  useEffect(() => {
    console.log(user);
  }, [])

  return (
    <>
      {user &&
        <>
          <Link component={RouterLink} to={PATH_DASHBOARD.user.account} underline="none" color="inherit">
            <StyledRoot>
              <CustomAvatar src={user?.photoURL} alt={user?.firstName} name={user?.firstName} />

              <Box sx={{ ml: 2, minWidth: 0 }}>
                <Typography variant="subtitle2" noWrap>
                  {user?.firstName}
                </Typography>

                <Typography variant="body2" noWrap sx={{ color: 'text.secondary' }}>
                  {user['cognito:groups'] && (user['cognito:groups'][0] === 'provider' || user['cognito:groups'][0] === 'admin') ? user['cognito:groups'][0] : ''}
                </Typography>
              </Box>

            </StyledRoot>
          </Link>
        </>

      }
      {!user &&
        <StyledRoot>
          <Stack spacing={2} width='100%'>
            <Button onClick={() => { navigate('/register') }} fullWidth variant='contained' size='large'>
              Create Account
            </Button>
            <Button onClick={() => { navigate(`/login?returnTo=${encodeURIComponent(pathname)}`) }} fullWidth variant='outlined' size='large'>
              Log In
            </Button>
          </Stack>
        </StyledRoot>
      }
    </>
  );
}
