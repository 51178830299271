import PropTypes from 'prop-types';
import { Navigate } from 'react-router-dom';
// routes
import { PATH_DASHBOARD } from '../routes/paths';
import { GRAPHQL_AUTH_MODE } from '@aws-amplify/api';
import * as queries from '../graphql/queries';
// components
import LoadingScreen from '../components/loading-screen';
//
import { useAuthContext } from './useAuthContext';
import { API } from 'aws-amplify';

// ----------------------------------------------------------------------

GuestGuard.propTypes = {
  children: PropTypes.node,
};

export default function GuestGuard({ children }) {
  const { isAuthenticated, isInitialized, user } = useAuthContext();

  if (isAuthenticated) {
    API.graphql({
      query: queries.getUserProfile,
      variables: { id: user.sub },
      authMode: GRAPHQL_AUTH_MODE.API_KEY,
    }).then((response) => {
      if (response.data.getUserProfile === null) {
        window.location = `/register?page=profileInfo`;
      } 
    }).catch(err => console.log(err))
    // return <Navigate to={PATH_DASHBOARD.root} />;
  }

  if (!isInitialized) {
    return <LoadingScreen />;
  }

  return <> {children} </>;
}
